import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Alert, AlertIcon, HStack, Text, Button } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef } from "react";
import { CherryPayApi } from "../../api/models";
import { useBusinessContext } from "../../context/ModelContext";
import { EditVenueForm } from "../../forms/EditVenueForm";
import { useApiClient } from "../../hooks/useApiClient";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useToast } from "../../hooks/useToast";

interface VenueModalProps {
  isOpen: boolean;
  venue: CherryPayApi.VenueDocument | null;
  onClose: () => void;
}
export const VenueModal = ({ isOpen, venue, onClose }: VenueModalProps) => {
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const initialRef = useRef<HTMLInputElement>(null);
  const { showErrorToast, showSuccessToast } = useToast();

  const isDeletdVenue = !!venue?._Meta?.DateDeleted;

  const {
    request: restoreDeletedVenue,
    isLoading: restoreInProgress,
    result: restoreVenueResult,
  } = useApiUpdateRequest(
    (apiClient) =>
      apiClient.restoreBusinessVenue(business.BusinessId, venue?.id!),
    [business, venue]
  );

  useEffect(() => {
    if (restoreVenueResult) {
      if (restoreVenueResult.ok) {
        showSuccessToast("Venue restored.");
        onClose();
      } else {
        showErrorToast("Venue restore failed.");
      }
    }
  }, [restoreVenueResult, onClose]);

  const onSuccess = useCallback(() => {
    showSuccessToast(venue ? "Venue updated" : "Venue created.");
    onClose();
  }, [showSuccessToast, onClose, venue]);

  const onFailure = useCallback(
    (message?: string) => {
      showErrorToast(
        message ?? (venue ? "Venue update failed." : "Venue creation failed.")
      );
    },
    [showErrorToast]
  );

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!!venue ? "Edit Venue" : "New Venue"}</ModalHeader>
        <ModalBody pb="6">
          {isDeletdVenue && (
            <Alert status="warning" marginBottom="2">
              <AlertIcon />
              <Text flex="1">This venue has been deleted.</Text>
              <Button
                size="sm"
                title="Restore deleted venue"
                isLoading={restoreInProgress}
                onClick={restoreDeletedVenue}
              >
                Restore
              </Button>
              <HStack></HStack>
            </Alert>
          )}
          <EditVenueForm
            venue={venue}
            apiClient={apiClient}
            business={business}
            onCancel={onClose}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
