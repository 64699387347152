import React from "react";
import { useUserContext } from "../../context/UserContext";
import { LogoutIcon } from "../../styles/icons";
import { HStack } from "@chakra-ui/layout";
import { IconButton, Text } from "@chakra-ui/react";

export const CurrentUser = () => {
  const { user, logout } = useUserContext();

  if (!user) {
    return null;
  }

  return (
    <HStack>
      <Text flex="1" color="#fff" textAlign="center">
        {user.displayName ?? user.email}
      </Text>
      <IconButton
        borderRadius="full"
        icon={<LogoutIcon />}
        aria-label="Logout"
        color="#fff"
        colorScheme="cherryButton"
        onClick={logout}
        title="Logout"
      />
    </HStack>
  );
};
