import { isEqual } from "lodash-es";
import { CherryPayApi } from "../api/models";

export interface RoleDiffStats {
  hasChanges: boolean;
  added: number;
  updated: number;
  removed: number;
}

/**
 * Returns diff stats for two lists of Roles
 * @param originalRoles
 * @param updatedRoles
 * @returns
 */
export const roleListDiff = (
  originalRoles: CherryPayApi.Role[],
  updatedRoles: CherryPayApi.Role[]
): RoleDiffStats => {
  let added = 0;
  let updated = 0;
  let removed = 0;

  const updatedRolesByName = updatedRoles.reduce<{
    [key: string]: CherryPayApi.Role;
  }>((obj, item) => {
    obj[item.Name] = item;
    return obj;
  }, {});

  const originalRolesByName = originalRoles.reduce<{
    [key: string]: CherryPayApi.Role;
  }>((obj, item) => {
    obj[item.Name] = item;
    return obj;
  }, {});

  for (const role of originalRoles) {
    const updatedRole = updatedRolesByName[role.Name];
    if (updatedRole && !isEqual(role, updatedRole)) {
      updated++;
    }

    if (!updatedRole) {
      removed++;
    }
  }

  for (const role of updatedRoles) {
    const originalRole = originalRolesByName[role.Name];
    if (!originalRole) {
      added++;
    }
  }

  return {
    hasChanges: added > 0 || updated > 0 || removed > 0,
    added,
    updated,
    removed,
  };
};
