import React from "react";
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/input";
import { Spinner } from "@chakra-ui/spinner";
import { InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export const SearchInput = (
  props: InputProps & {
    isLoading?: boolean;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
  }
) => {
  const { isLoading, ...inputProps } = props;
  return (
    <InputGroup backgroundColor="#fff" shadow="sm">
      <InputLeftElement children={<SearchIcon />} />
      <Input {...inputProps} ref={props.inputRef} />
      {props.isLoading && <InputRightElement children={<Spinner />} />}
    </InputGroup>
  );
};
