import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Box, HStack } from "@chakra-ui/layout";

import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { VenuesTable } from "../../components/VenuesTable/VenuesTable";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { Checkbox } from "@chakra-ui/checkbox";
import { useCheckboxState } from "../../hooks/useCheckboxState";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useApiClient } from "../../hooks/useApiClient";
import { useModalState } from "../../hooks/useModalState";
import { CherryPayApi } from "../../api/models";
import { VenueModal } from "../../components/VenueModal/VenueModal";
import { Button } from "@chakra-ui/react";
import { PlusIcon } from "../../styles/icons";
import { useToast } from "../../hooks/useToast";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

export const BusinessConfig = () => {
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const { checked: showDeletedVenues, onChange: onChangeDeletedVenuesCheck } =
    useCheckboxState(false);

  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const modalState = useModalState<CherryPayApi.VenueDocument | null>();

  const {
    debouncedQuery,
    isDebouncing,
    onChange: onChangeSearch,
    query,
  } = useSearchInputState();

  const {
    data: venues,
    result,
    refresh,
    isLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getBusinessVenues(business.BusinessId, {
        q: debouncedQuery,
        includeDeleted: showDeletedVenues ? "true" : "false",
      }),
    [business.BusinessId, debouncedQuery, showDeletedVenues]
  );

  useEffect(() => {
    if (!modalState.isOpen) {
      refresh();
    }
  }, [refresh, modalState.isOpen]);

  const deleteVenueRequest = useApiUpdateRequest(
    (apiClient, venueId: string) =>
      apiClient.deleteBusinessVenue(business.BusinessId, venueId),
    [business]
  );

  const onClickNewVenue = useCallback(
    () => modalState.onOpen(null),
    [modalState.onOpen]
  );

  const onClickEditVenue = useCallback(
    (venue: CherryPayApi.VenueDocument) => modalState.onOpen(venue),
    [modalState.onOpen]
  );

  useEffect(() => {
    if (deleteVenueRequest.result) {
      if (deleteVenueRequest.result.ok) {
        showSuccessToast("Venue deleted.");
        refresh();
      } else {
        showErrorToast(
          deleteVenueRequest.result.message ?? "Failed to delete venue."
        );
      }
    }
  }, [deleteVenueRequest.result, refresh]);

  const onClickDeleteVenue = useCallback(
    (venue: CherryPayApi.VenueDocument) =>
      showConfirmationDialog(
        "Delete Venue?",
        "Are you sure you wish to delete this venue",
        "Delete"
      ).then(({ ok }) => {
        if (ok) {
          deleteVenueRequest.request(venue.id);
        }
      }),
    [
      showSuccessToast,
      showErrorToast,
      showConfirmationDialog,
      apiClient,
      refresh,
    ]
  );

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/configuration`}
            >
              Configuration
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Business Configuration</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <HStack w="100%">
          <Box flex="4" maxWidth="400px">
            <SearchInput
              isLoading={isDebouncing || isLoading}
              value={query}
              onChange={onChangeSearch}
              placeholder="Search venues..."
            />
          </Box>
          <Box flex="1">
            <Checkbox
              checked={showDeletedVenues}
              onChange={onChangeDeletedVenuesCheck}
              title="Include deleted venues in the list."
            >
              Deleted Venues
            </Checkbox>
          </Box>
          <Box marginLeft="auto">
            <Button
              leftIcon={<PlusIcon />}
              onClick={onClickNewVenue}
              colorScheme="cherryButton"
            >
              New Venue
            </Button>
          </Box>
        </HStack>

        <VenuesTable
          venues={venues}
          isLoading={!venues && isLoading}
          disableActions={deleteVenueRequest.isLoading}
          onClickEditVenue={onClickEditVenue}
          onClickDeleteVenue={onClickDeleteVenue}
        />
        {result && !result.ok && (
          <ErrorMessage>
            {result.message ?? "Error fetching business venues."}
          </ErrorMessage>
        )}
        {!venues && isLoading && <LoadingIndicator />}
        <VenueModal
          venue={modalState.opts}
          isOpen={modalState.isOpen}
          onClose={modalState.onClose}
        />
        {confirmationElement}
      </PageContent>
    </>
  );
};
