import React, { useEffect, useMemo, useState } from "react";
import { Input, InputProps } from "@chakra-ui/input";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  Text,
  HStack,
  IconButton,
} from "@chakra-ui/react";

import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { useSearchInputState } from "../../../hooks/useSearchInputState";
import { SearchInput } from "../../SearchInput/SearchInput";
import { useApiRequest } from "../../../hooks/useApiRequest";
import { CherryPayApi } from "../../../api/models";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import { DeleteIcon } from "../../../styles/icons";

const lookupPlaceholders: { [key: string]: string } = {
  abn: "Enter an ABN...",
  acn: "Enter an ACN...",
  name: "Search by name...",
};

interface ABNLookupFieldProps extends BaseFieldProps {
  searchInputRef?: React.MutableRefObject<HTMLInputElement>;
}

const ABNRecordList = ({
  abnRecords,
  onSelectAbnRecord,
}: {
  abnRecords: CherryPayApi.ABNRecord[] | null;
  onSelectAbnRecord: (record: CherryPayApi.ABNRecord) => void;
}) => {
  const listItems = useMemo(() => {
    return (abnRecords ?? []).map((record, i) => (
      <HStack
        key={`${record.ABN}-${i}`}
        w="100%"
        padding="2"
        border="1px solid #efefef"
        backgroundColor="#FAFAFA"
        borderRadius="md"
        as="button"
        alignItems="start"
        textAlign="left"
        onClick={() => onSelectAbnRecord(record)}
      >
        <VStack flex="1" spacing="0">
          <Text w="100%" fontSize="md" color="gray.700" fontWeight="600">
            {record.ABN}
          </Text>
          <Text w="100%" color="gray.600" fontSize="sm">
            {record.Name} - {record.State} {record.Postcode}
          </Text>
        </VStack>
      </HStack>
    ));
  }, [abnRecords, onSelectAbnRecord]);

  return (
    <VStack width="100%" spacing="1">
      {listItems}
    </VStack>
  );
};

const ABNLookup = ({
  value,
  onChange,
  searchInputRef,
}: {
  value: CherryPayApi.ABNRecord | null;
  onChange: (value: CherryPayApi.ABNRecord | null) => void;
  searchInputRef?: React.MutableRefObject<HTMLInputElement>;
}) => {
  const [lookupType, setLookupType] = useState<string>("name");
  const {
    query,
    debouncedQuery,
    isDebouncing,
    onChange: onChangeQuery,
    setQuery,
  } = useSearchInputState("", 400);

  // Reset the search input when the lookup type changes
  useEffect(() => {
    setQuery("");
  }, [lookupType, setQuery, value]);

  const hasValidQuery = query.trim() !== "";

  const {
    data: abnRecords,
    isLoading,
    result,
  } = useApiRequest(
    (apiClient) => {
      if (!hasValidQuery) {
        return null;
      }
      return apiClient.lookupAbnRecord(lookupType, debouncedQuery);
    },
    [lookupType, debouncedQuery]
  );

  if (value) {
    return (
      <HStack
        w="100%"
        padding="2"
        border="1px solid #efefef"
        backgroundColor="#FAFAFA"
        borderRadius="md"
      >
        <VStack flex="1" spacing="0">
          <Text w="100%" fontSize="md" color="gray.700" fontWeight="600">
            {value.ABN}
          </Text>
          <Text w="100%" color="gray.600" fontSize="sm">
            {value.Name} - {value.State} {value.Postcode}
          </Text>
        </VStack>
        <IconButton
          icon={<DeleteIcon />}
          onClick={() => onChange(null)}
          variant="ghost"
          aria-label="Remove"
        />
      </HStack>
    );
  }
  return (
    <VStack w="100%" spacing="2" alignItems="start">
      <RadioGroup value={lookupType} onChange={setLookupType}>
        <Stack direction="row">
          <Radio value="name" colorScheme="cherryButton">
            Name
          </Radio>
          <Radio value="abn">ABN</Radio>
          <Radio value="acn">ACN</Radio>
        </Stack>
      </RadioGroup>
      <SearchInput
        placeholder={lookupPlaceholders[lookupType] ?? "Search..."}
        onChange={onChangeQuery}
        value={query}
        isLoading={isDebouncing || (hasValidQuery && isLoading)}
        inputRef={searchInputRef}
      />
      {hasValidQuery && !value && (
        <ABNRecordList abnRecords={abnRecords} onSelectAbnRecord={onChange} />
      )}
      {hasValidQuery && !isLoading && result?.ok === false && (
        <ErrorMessage>
          {result.message ?? "There was an error while looking up ABN records."}
        </ErrorMessage>
      )}
    </VStack>
  );
};

export const ABNLookupField = ({
  name,
  label,
  id,
  helperText,
  searchInputRef,
}: ABNLookupFieldProps) => {
  return (
    <FieldWrapper name={name} id={id} label={label} helperText={helperText}>
      {({ field, form }) => (
        <>
          <ABNLookup
            value={field.value}
            onChange={(record) => {
              form.setFieldValue(field.name, record, true);
            }}
            searchInputRef={searchInputRef}
          />
        </>
      )}
    </FieldWrapper>
  );
};
