import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from "@chakra-ui/react";
import React from "react";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface IntegerFieldProps extends BaseFieldProps {}

const parseValue = (value: string) => {
  const parsed = value.replace(/[\$,]/g, "");

  if (parsed === "") {
    return "";
  }

  return parseFloat(parsed);
};

export const IntegerField = ({
  name,
  label,
  id,
  ...numberInputProps
}: IntegerFieldProps & NumberInputProps) => {
  return (
    <FieldWrapper name={name} id={id} label={label}>
      {({ field, form }) => {
        return (
          <NumberInput
            precision={0}
            value={field.value}
            onChange={(strValue) => {
              form.setFieldValue(field.name, parseValue(strValue), true);
            }}
            onBlur={field.onBlur}
            name={field.name}
            pattern="[0-9]*"
            {...numberInputProps}
          >
            <NumberInputField placeholder={numberInputProps.placeholder} />
          </NumberInput>
        );
      }}
    </FieldWrapper>
  );
};
