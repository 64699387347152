import React, { useMemo } from "react";
import { ApiRequestState } from "../../hooks/useApiRequest";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

type ApiStateResponseType<T> = T extends ApiRequestState<infer TResponse>
  ? TResponse
  : never;

type ApiStateResponseTypes<T extends [...any[]]> = {
  [Index in keyof T]: ApiStateResponseType<T[Index]>;
};

interface ApiLoadingIndicatorProps<T extends ApiRequestState<any>[]> {
  renderLoading?: () => JSX.Element;
  renderError?: () => JSX.Element;
  apiStates: T;
  children: (results: ApiStateResponseTypes<T>) => JSX.Element;
}

export function ApiLoadingIndicator<T extends ApiRequestState<any>[]>({
  children,
  apiStates,
  renderError,
  renderLoading,
}: ApiLoadingIndicatorProps<T>) {
  const isLoading = useMemo(
    () =>
      !!apiStates.find(
        (state: ApiRequestState<any>) => state.isLoading === true
      ),
    apiStates
  );

  const hasError = useMemo(
    () =>
      !!apiStates.find(
        (state: ApiRequestState<any>) =>
          state.isLoading === false && state.result?.ok === false
      ),
    apiStates
  );

  if (isLoading) {
    return renderLoading ? renderLoading() : <LoadingIndicator color="#333" />;
  }

  if (hasError) {
    return renderError ? (
      renderError()
    ) : (
      <ErrorMessage>{"An error was encountere"}</ErrorMessage>
    );
  }

  const responses = apiStates.map((item) =>
    item.result?.ok ? item.data : null
  );
  return children(responses as any);
}
