import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  AlertIcon,
  HStack,
  Text,
  Button,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Container,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CherryPayApi } from "../../api/models";
import { useBusinessContext } from "../../context/ModelContext";
import { EditVenueForm } from "../../forms/EditVenueForm";
import { useApiClient } from "../../hooks/useApiClient";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useToast } from "../../hooks/useToast";
import { BusinessBreadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { PageContent } from "../PageContent/PageContent";
import { PageHeading } from "../PageHeading/PageHeading";
import { TermsConditionsForm } from "../../forms/TermsConditionsForm";
import { useFirebase } from "../../context/FirebaseContext";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { TermsConditionsDisplay } from "../TermsConditionsDisplay/TermsConditionsDisplay";
import { DocumentSnapshot } from "firebase/firestore";

interface TermsConditionsPageProps {
  isOpen: boolean;
  venue: CherryPayApi.VenueDocument | null;
  onClose: () => void;
}
export const TermsConditionsPage = () => {
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const initialRef = useRef<HTMLInputElement>(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const { db } = useFirebase();
  const { termsId } = useParams();
  const [item, setItem] = useState<any>(null);

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    navigate(`/businesses/${business.BusinessId}/terms-conditions`);
  }, []);

  const onSuccess = useCallback(
    (result?: DocumentSnapshot, goToList?: boolean) => {
      if (result?.id && !goToList) {
        navigate(
          `/businesses/${business.BusinessId}/terms-conditions/${result.id}`
        );
      } else if (goToList) {
        navigate(`/businesses/${business.BusinessId}/terms-conditions`);
      } else {
        showSuccessToast("Saved successfully");
        getItem();
      }
    },
    [showSuccessToast]
  );

  const onFailure = useCallback(
    (message?: string) => {
      showErrorToast(message ?? "Error occurred");
    },
    [showErrorToast]
  );

  const getItem = useCallback(async () => {
    if (termsId) {
      const fetchedItem = await apiClient.getTermsConditionsSingle(
        db,
        business.BusinessId,
        termsId
      );
      console.log(
        "aaaaa",
        { db, businessId: business.BusinessId, termsId },
        fetchedItem.data()
      );
      if (fetchedItem.exists()) {
        setItem({ ...fetchedItem.data(), id: fetchedItem.id });
      }
    }
  }, [termsId]);

  useEffect(() => {
    getItem();
  }, [termsId]);

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>{}</BusinessBreadcrumbs>
        <PageHeading.Title>Terms &amp; Conditions</PageHeading.Title>
      </PageHeading>
      <PageContent>
        {(item || !termsId) && (
          <Flex alignContent="space-evenly" w="100%" justifyContent="center">
            <Box flexBasis="0" flexGrow={1} maxW="600px">
              <TermsConditionsForm
                venue={item || null}
                apiClient={apiClient}
                db={db}
                business={business}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            </Box>
            {item && (
              <Container
                display={["none", "none", "block"]}
                flexBasis="0"
                flexGrow={1}
                maxW="none"
              >
                <Box m="20px" p="15px" borderRadius="10px">
                  <TermsConditionsDisplay item={item} />
                </Box>
              </Container>
            )}
          </Flex>
        )}

        {termsId && !item && <LoadingIndicator color="gray.600" />}
      </PageContent>
    </>

    // <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
    //   <ModalOverlay />
    //   <ModalContent>
    //     <ModalHeader>{!!venue ? "Edit Venue" : "New Venue"}</ModalHeader>
    //     <ModalBody pb="6">
    //       {isDeletdVenue && (
    //         <Alert status="warning" marginBottom="2">
    //           <AlertIcon />
    //           <Text flex="1">This venue has been deleted.</Text>
    //           <Button
    //             size="sm"
    //             title="Restore deleted venue"
    //             isLoading={restoreInProgress}
    //             onClick={restoreDeletedVenue}
    //           >
    //             Restore
    //           </Button>
    //           <HStack></HStack>
    //         </Alert>
    //       )}
    //       <EditVenueForm
    //         venue={venue}
    //         apiClient={apiClient}
    //         business={business}
    //         onCancel={onClose}
    //         onSuccess={onSuccess}
    //         onFailure={onFailure}
    //       />
    //     </ModalBody>
    //   </ModalContent>
    // </Modal>
  );
};
