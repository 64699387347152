import React, { useCallback, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useApiClient } from "../../hooks/useApiClient";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { useToast } from "../../hooks/useToast";
import { InvitationForm } from "../../forms/InvitationForm";
import { ApiLoadingIndicator } from "../ApiLoadingIndicator/ApiLoadingIndicator";

interface InvitationModalProps {
  memberId?: string;
  invitationType?: "digital-membership" | "cherry-pay-card";
  isOpen: boolean;
  onClose: () => void;
}

export const InvitationModal = ({
  memberId,
  invitationType,
  isOpen,
  onClose,
}: InvitationModalProps) => {
  const initialRef = useRef<HTMLInputElement>(null);
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const { showErrorToast, showSuccessToast } = useToast();
  const apiRequest = useApiRequest(
    (apiClient) => {
      if (!isOpen || !memberId) {
        return null;
      }

      return apiClient.getMember(business.BusinessId, memberId);
    },
    [memberId, business]
  );

  const onSuccess = useCallback(() => {
    showSuccessToast("Invitation sent");
    onClose();
  }, [onClose, showSuccessToast]);

  const onFailure = useCallback(() => {
    showErrorToast("Failed to send invitation");
  }, [showErrorToast]);

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {invitationType === "digital-membership"
            ? "Digital Membership Invitation"
            : "CherryPay Card Invitation"}
        </ModalHeader>
        <ModalBody pb="6">
          <ApiLoadingIndicator
            renderLoading={() => <LoadingIndicator color="#333" />}
            renderError={() => (
              <ErrorMessage>
                {"An error was encountered while loading the form."}
              </ErrorMessage>
            )}
            apiStates={[apiRequest]}
          >
            {([member]) => (
              <InvitationForm
                submitLabel="Send Invitation"
                invitationType={invitationType!}
                apiClient={apiClient}
                businessId={business.BusinessId}
                member={member}
                onCancel={onClose}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            )}
          </ApiLoadingIndicator>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
