import { CherryPayApi } from "./models";
import axios, { Axios, AxiosResponse } from "axios";
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  query,
  setDoc,
  getDoc,
  DocumentSnapshot,
  getDocs,
  QuerySnapshot,
  DocumentData,
  orderBy,
  deleteDoc,
  where,
  DocumentReference,
} from "firebase/firestore";

export type ApiError = { statusCode?: number; message: string; ok: false };
export type ApiResult<T> =
  | { statusCode: number; data: T; ok: true; next?: string }
  | ApiError;

const apiResult = <T>(
  axiosResponse: AxiosResponse,
  getData?: (data: any) => T
): ApiResult<T> => {
  if (axiosResponse.status >= 200 && axiosResponse.status <= 299) {
    // If the response body is paginated we'll find a url for the next page.
    const next = axiosResponse.data?._links?.next?.relativeUrl ?? undefined;

    return {
      data: (getData ? getData(axiosResponse.data) : axiosResponse.data) as T,
      statusCode: axiosResponse.status,
      ok: true,
      next,
    };
  } else if (axiosResponse.status) {
    return {
      statusCode: axiosResponse.status,
      message:
        axiosResponse.data?.ErrorMessage ??
        `HTTP ${axiosResponse.status} error.`,
      ok: false,
    };
  } else {
    return {
      statusCode: axiosResponse.status,
      message: `Unknown error.`,
      ok: false,
    };
  }
};

export class ApiClient {
  cherryPayClient: Axios;

  constructor(user?: User) {
    this.cherryPayClient = axios.create({
      baseURL: `${API_BASE_URL}`,
      // HTTP errors will not throw exceptions
      validateStatus: () => true,
    });

    if (user) {
      this.cherryPayClient.interceptors.request.use((config) => {
        // Fetches the current access token. If it has expired or is close to exiring a new token will be fetched.
        return user.getIdTokenResult().then((idToken) => {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers!.Authorization = `Bearer ${idToken.token}`;
          return config;
        });
      });
    }
  }

  async getCurrentUser(): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.get("/cherrypay/v1/me");
    return apiResult(result);
  }

  /**
   * Returns a list of venues the current user has permission to manage.
   * @returns
   */
  async getBusinesses(
    businessId?: string
  ): Promise<ApiResult<CherryPayApi.BusinessSummary[]>> {
    const result = await this.cherryPayClient.get(
      "/cherrypay/v1/business/summary",
      {
        params: businessId ? { businessId } : {},
      }
    );

    return apiResult<CherryPayApi.BusinessSummary[]>(result);
  }

  /**
   * Returns an individual business summary
   * @returns
   */
  async getBusiness(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.BusinessSummary | null>> {
    const result = await this.cherryPayClient.get(
      "/cherrypay/v1/business/summary",
      {
        params: { businessId },
      }
    );

    // const result: any = {
    //   status: 200,
    //   data: [
    //     {
    //       BusinessId: businessId,
    //       DisplayName: "Test",
    //       VenueSummaries: [],
    //     },
    //   ],
    // };

    const findBusinessInResults = (data: any) =>
      data.find(
        (business: CherryPayApi.BusinessSummary) =>
          business.BusinessId === businessId
      ) ?? null;

    return apiResult<CherryPayApi.BusinessSummary | null>(
      result,
      findBusinessInResults
    );
  }

  async searchMembers(
    businessId: string,
    query?: string
  ): Promise<ApiResult<CherryPayApi.MemberQueryResultSetResponse>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members`,
      {
        params: { q: query, limit: 30 },
      }
    );

    return apiResult<CherryPayApi.MemberQueryResultSetResponse>(result);
  }

  async searchMembersPage(
    url: string
  ): Promise<ApiResult<CherryPayApi.MemberQueryResultSetResponse>> {
    const result = await this.cherryPayClient.get(url);

    return apiResult<CherryPayApi.MemberQueryResultSetResponse>(result);
  }

  async getMember(
    businessId: string,
    memberId: string
  ): Promise<ApiResult<CherryPayApi.Member>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}`
    );

    return apiResult(result, (data) => data.Results[0]);
  }

  async searchUsers(
    businessId: string,
    query: string
  ): Promise<ApiResult<CherryPayApi.UserAccountQueryResultSetResponse>> {
    const queryParam = query.length > 0 ? query : undefined;

    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/user`,
      {
        params: { q: queryParam, limit: 30 },
      }
    );

    return apiResult(result);
  }

  async searchUsersPage(
    url: string
  ): Promise<ApiResult<CherryPayApi.UserAccountQueryResultSetResponse>> {
    const result = await this.cherryPayClient.get(url);

    return apiResult(result);
  }

  async getUser(
    businessId: string,
    userUid: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result =
      await this.cherryPayClient.get<CherryPayApi.UserAccountQueryResultSetResponse>(
        `/cherrypay/v1/${businessId}/user`,
        {
          params: { limit: 100 },
        }
      );

    const findUserInResult = (data: any) =>
      data.Results.find(
        (user: CherryPayApi.UserAccount) => user.Uid === userUid
      );

    return apiResult(result, findUserInResult);
  }

  async createUser(
    user: { Username: string; DisplayName: string; Password?: string },
    businessId: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/user`,
      JSON.stringify(user),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    return apiResult(result);
  }

  async updateUserRole(
    businessId: string,
    userUid: string,
    role: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/user/${userUid}/role`,
      { Value: role }
    );

    return apiResult(result);
  }

  async updateUserAuthorisedVenues(
    businessId: string,
    userUid: string,
    authorisedVenues: string[]
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/user/${userUid}/authorised-venue`,
      authorisedVenues
    );

    return apiResult(result);
  }

  async deleteUser(
    businessId: string,
    userUid: string,
    userEmail: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.delete(
      `/cherrypay/v1/${businessId}/user/${userUid}`,
      { params: { email: userEmail } }
    );

    return apiResult(result);
  }

  async getBusinessPermissions(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.Permission[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/configuration/permission`
    );

    return apiResult(result);
  }

  async getBusinessRoles(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.Role[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/configuration/roles`
    );

    return apiResult(result);
  }

  async updateBusinessRoles(
    businessId: string,
    roles: { Name: string }[]
  ): Promise<ApiResult<CherryPayApi.Role[]>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/configuration/roles`,
      roles
    );

    return apiResult(result);
  }

  async lookupAbnRecord(
    method: string,
    query: string
  ): Promise<ApiResult<CherryPayApi.ABNRecord[]>> {
    if (["abn", "acn", "name"].indexOf(method) === -1) {
      throw new Error(`Invalid ABN lookup type '${method}'`);
    }

    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/business/lookup/${method}`,
      { params: { q: query } }
    );

    return apiResult(result);
  }

  async createBusiness(
    abnRecord: CherryPayApi.ABNRecord
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.post(
      "/cherrypay/v1/business",
      abnRecord
    );

    return apiResult(result);
  }

  async createTermsConditions(
    db: Firestore,
    businessId: string,
    data: any
  ): Promise<DocumentReference<any>> {
    const collRef = collection(db, "cherryplay", businessId, "terms");
    data.businessId = businessId;
    data.createdAt = new Date();
    delete data.id;
    return addDoc(collRef, data);
  }

  async updateTermsConditions(
    db: Firestore,
    businessId: string,
    docId: string,
    data: any
  ): Promise<any> {
    data.updatedAt = new Date();
    const docRef = doc(db, "cherryplay", businessId, "terms", docId);
    return setDoc(docRef, data, { merge: true });
  }

  async getTermsConditionsSingle(
    db: Firestore,
    businessId: string,
    docId: string
  ): Promise<DocumentSnapshot> {
    const docRef = doc(db, "cherryplay", businessId, "terms", docId);
    return getDoc(docRef);
  }

  async deleteTermsConditions(
    db: Firestore,
    businessId: string,
    docId: string
  ): Promise<any> {
    const docRef = doc(db, "cherryplay", businessId, "terms", docId);
    return setDoc(docRef, { createdAt: null }, { merge: true });
  }

  async getTermsConditionsList(
    db: Firestore,
    businessId: string
  ): Promise<QuerySnapshot<DocumentData>> {
    const collRef = collection(db, "cherryplay", businessId, "terms");
    return getDocs(
      query(
        collRef,
        where("createdAt", "!=", null),
        orderBy("createdAt", "desc")
      )
    );
  }

  async getBusinessVenues(
    businessId: string,
    params: { q?: string; includeDeleted?: string }
  ): Promise<ApiResult<CherryPayApi.VenueDocument[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/venues`,
      {
        params: {
          ...params,
          limit: 100,
        },
      }
    );

    return apiResult(result, (data) => data.Results);
  }

  async updateBusinessVenue(
    businessId: string,
    venueId: string,
    venueUpdates: Partial<CherryPayApi.VenueDocument>
  ): Promise<ApiResult<CherryPayApi.VenueDocument>> {
    const result = await this.cherryPayClient.patch(
      `/cherrypay/v1/${businessId}/venues/${venueId}`,
      venueUpdates
    );

    return apiResult(result);
  }

  async createBusinessVenue(
    businessId: string,
    venue: Partial<CherryPayApi.VenueDocument>
  ): Promise<ApiResult<CherryPayApi.VenueDocument>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/venues`,
      venue
    );

    return apiResult(result);
  }

  async deleteBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.delete(
      `/cherrypay/v1/${businessId}/venues/${venueId}`
    );

    return apiResult(result);
  }
  async restoreBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.delete(
      `/cherrypay/v1/${businessId}/venues/${venueId}`,
      { params: { undelete: "true" } }
    );

    return apiResult(result);
  }

  async getPointsRequestModalObjects(
    businessId: string,
    memberId: string
  ): Promise<
    ApiResult<{
      Member: CherryPayApi.Member;
      PointsTypes: CherryPayApi.PointsType[];
    }>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points-request-modal`
    );

    return apiResult(result);
  }

  async getPointsToCardTransferModalObjects(
    businessId: string,
    memberId: string
  ): Promise<
    ApiResult<{
      Member: CherryPayApi.Member;
      PointsTypes: CherryPayApi.PointsType[];
      CherryPayCards: CherryPayApi.CherryPayCardListItem[];
      Minimum: number | null;
      Maximum: number | null;
    }>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points-to-card-transfer-request-modal`
    );

    return apiResult(result);
  }

  async getCPCCardInviteModalObjects(
    businessId: string,
    memberId: string,
    cardProgramType: "instantgift" | "reloadable"
  ): Promise<
    ApiResult<{
      Member: CherryPayApi.Member;
      Minimum?: number | null;
      Maximum?: number | null;
      IsSMSOptional?: boolean;
    }>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/issue-member-cherrypay-card-request-modal/${cardProgramType}`
    );

    return apiResult(result);
  }

  async getPointsBalance(
    businessId: string,
    memberId: string,
    pointsTypeId: string
  ): Promise<ApiResult<CherryPayApi.PointsBalance>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points/${pointsTypeId}`
    );

    return apiResult(result);
  }

  async getDefaultPointsBalance(
    businessId: string,
    memberId: string
  ): Promise<ApiResult<CherryPayApi.PointsBalance>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points`
    );

    return apiResult(result);
  }

  async awardPoints(
    businessId: string,
    memberId: string,
    pointsTypeId: string,
    payload: {
      PointsByDollarValue: number;
      PointsByQuantity: number;
      Note: string;
      CherryPayUserName: string;
    }
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.post(
      `cherrypay/v1/${businessId}/members/${memberId}/points/${pointsTypeId}/award`,
      { ...payload }
    );

    return apiResult(result);
  }

  async getOverridableProperties(businessId: string): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/overridable-properties`
    );

    return apiResult(result);
  }

  async setMemberProperties(
    businessId: string,
    memberId: string,
    properties: CherryPayApi.OverriablePropertyPayload<CherryPayApi.MemberOverridableProperties>
  ): Promise<ApiResult<CherryPayApi.Member>> {
    const result = await this.cherryPayClient.patch(
      `/cherrypay/v1/${businessId}/members/${memberId}/override-property`,
      properties
    );

    return apiResult(result);
  }

  async sendDigitalMembershipInvitation(
    businessId: string,
    memberId: string,
    contactDetailType: CherryPayApi.ContactDetailsType
  ): Promise<ApiResult<{}>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/members/${memberId}/invite/dmc`,
      {
        Value: contactDetailType,
      }
    );

    return apiResult(result);
  }

  async sendCherryPayCardInvitation(
    businessId: string,
    memberId: string,
    contactDetailType: CherryPayApi.ContactDetailsType,
    initialCardValue: number,
    sendAppDownloadSms: boolean | null,
    cardProgramType: "instantgift" | "reloadable"
  ): Promise<ApiResult<{}>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/members/${memberId}/issue/cpc/${cardProgramType}`,
      {
        MemberContactDetailsType: contactDetailType,
        InitialCardValue: initialCardValue,
        SendAppDownloadSMS: sendAppDownloadSms,
      }
    );

    return apiResult(result);
  }

  async transferPointsToCard(
    businessId: string,
    memberId: string,
    cardId: string,
    pointsTypeId: string,
    payload: {
      PointsByDollarValue: number;
      PointsByQuantity: number;
      Note: string;
      Description: string;
      CherryPayUserName: string;
    }
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.post(
      `cherrypay/v1/${businessId}/members/${memberId}/cards/${cardId}/funds/transfer-points/${pointsTypeId}`,
      { ...payload }
    );

    return apiResult(result);
  }
}
