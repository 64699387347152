import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  AlertIcon,
  HStack,
  Text,
  Button,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CherryPayApi } from "../../api/models";
import { useBusinessContext } from "../../context/ModelContext";
import { EditVenueForm } from "../../forms/EditVenueForm";
import { useApiClient } from "../../hooks/useApiClient";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useToast } from "../../hooks/useToast";
import { BusinessBreadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { PageContent } from "../PageContent/PageContent";
import { PageHeading } from "../PageHeading/PageHeading";
import { TermsConditionsForm } from "../../forms/TermsConditionsForm";
import { useFirebase } from "../../context/FirebaseContext";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { TermsConditionsDisplay } from "../TermsConditionsDisplay/TermsConditionsDisplay";
import { DocumentSnapshot } from "firebase/firestore";

interface TermsConditionsViewProps {
  isOpen: boolean;
  venue: CherryPayApi.VenueDocument | null;
  onClose: () => void;
}
export const TermsConditionsView = () => {
  const apiClient = useApiClient();
  const initialRef = useRef<HTMLInputElement>(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const { db } = useFirebase();
  const { businessId, termsId } = useParams();
  const [item, setItem] = useState<any>(null);

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const onSuccess = useCallback(
    (result?: DocumentSnapshot, goToList?: boolean) => {
      if (result?.id && !goToList) {
        navigate(`/businesses/${businessId}/terms-conditions/${result.id}`);
      } else if (goToList) {
        navigate(`/businesses/${businessId}/terms-conditions`);
      } else {
        getItem();
      }
    },
    [showSuccessToast]
  );

  const onFailure = useCallback(
    (message?: string) => {
      showErrorToast(message ?? "Error occurred");
    },
    [showErrorToast]
  );

  const getItem = useCallback(async () => {
    if (termsId) {
      const fetchedItem = await apiClient.getTermsConditionsSingle(
        db,
        businessId ?? "",
        termsId
      );
      console.log(
        "aaaaa",
        { db, businessId: businessId, termsId },
        fetchedItem.data()
      );
      if (fetchedItem.exists()) {
        setItem({ ...fetchedItem.data(), id: fetchedItem.id });
      }
    }
  }, [termsId]);

  useEffect(() => {
    getItem();
  }, [termsId]);

  const css = `
    html, body {
      background: #FAFAFA;
    }
    
  `;

  return (
    <>
      <style>{css}</style>
      <PageContent>
        {item && (
          <Box mx="20px" p="15px" borderRadius="10px" maxW="1024px">
            <TermsConditionsDisplay item={item} />
          </Box>
        )}

        {!item && <LoadingIndicator color="gray.600" />}
      </PageContent>
    </>
  );
};
