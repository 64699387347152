import React, { useCallback, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useApiClient } from "../../hooks/useApiClient";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { useToast } from "../../hooks/useToast";
import { InvitationForm } from "../../forms/InvitationForm";
import { ApiLoadingIndicator } from "../ApiLoadingIndicator/ApiLoadingIndicator";

type CPCTypes = "instantgift" | "reloadable";

interface CherryPayCardModalProps {
  memberId?: string;
  cpcType: CPCTypes;
  isOpen: boolean;
  onClose: () => void;
}

const modalTitle: { [P in CPCTypes]: string } = {
  instantgift: "New CherryPay Instant Gift Card",
  reloadable: "New CherryPay Reloadable Card",
};

export const CherryPayCardModal = ({
  memberId,
  cpcType,
  isOpen,
  onClose,
}: CherryPayCardModalProps) => {
  const initialRef = useRef<HTMLInputElement>(null);
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const { showErrorToast, showSuccessToast } = useToast();
  const apiRequest = useApiRequest(
    (apiClient) => {
      if (!isOpen || !memberId) {
        return null;
      }

      return apiClient.getCPCCardInviteModalObjects(
        business.BusinessId,
        memberId,
        cpcType
      );
    },
    [memberId, business, cpcType]
  );

  const onSuccess = useCallback(() => {
    showSuccessToast("Invitation sent");
    onClose();
  }, [onClose, showSuccessToast]);

  const onFailure = useCallback(() => {
    showErrorToast("Failed to send invitation");
  }, [showErrorToast]);

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle[cpcType]}</ModalHeader>
        <ModalBody pb="6">
          <ApiLoadingIndicator
            renderLoading={() => <LoadingIndicator color="#333" />}
            renderError={() => (
              <ErrorMessage>
                {"An error was encountered while loading the form."}
              </ErrorMessage>
            )}
            apiStates={[apiRequest]}
          >
            {([{ Member, Minimum, Maximum, IsSMSOptional }]) => (
              <InvitationForm
                invitationType="cherry-pay-card"
                cpcType={cpcType}
                submitLabel={
                  cpcType === "instantgift" ? "Send SMS" : "Create Card"
                }
                apiClient={apiClient}
                businessId={business.BusinessId}
                member={Member}
                minimumBalance={Minimum ?? null}
                maximumBalance={Maximum ?? null}
                isSmsOptional={IsSMSOptional ?? null}
                onCancel={onClose}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            )}
          </ApiLoadingIndicator>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
