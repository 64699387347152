import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/input";
import React from "react";
import { CheckIcon } from "../../../styles/icons";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface DateFieldProps extends BaseFieldProps {
  isValid?: boolean;
  type: "date" | "datetime-local";
}

export const DateField = ({
  name,
  label,
  id,
  helperText,
  isValid,
  ...inputProps
}: DateFieldProps & InputProps) => {
  return (
    <FieldWrapper name={name} id={id} label={label} helperText={helperText}>
      {({ field }) => {
        return (
          <InputGroup backgroundColor="#fff" shadow="sm">
            <Input {...field} {...inputProps} />
            {isValid && (
              <InputRightElement children={<CheckIcon color="green.400" />} />
            )}
          </InputGroup>
        );
      }}
    </FieldWrapper>
  );
};
