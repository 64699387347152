import React, { useCallback, useEffect } from "react";
import {
  Route,
  Routes,
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import { Box, Center, VStack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Image } from "@chakra-ui/react";

import { useUserContext } from "./context/UserContext";
import { Login } from "./pages/Login/Login";
import { Users } from "./pages/Users/Users";
import { MemberDetail } from "./pages/MemberDetail/MemberDetail";
import { Business } from "./pages/Business/Business";
import { BusinessList } from "./pages/BusinessList/BusinessList";

import { Layout } from "./components/Layout/Layout";
import { CurrentUser } from "./components/CurrentUser/CurrentUser";
import { AppMenu } from "./components/AppMenu/AppMenu";
import { AppTitle } from "./components/AppTitle/AppTitle";
import { ModelContextProvider } from "./context/ModelContext";
import { ErrorHandler } from "./components/ErrorBoundary/ErrorBoundary";
import { HStack } from "@chakra-ui/react";
import { BusinessRoles } from "./pages/BusinessRoles/BusinessRoles";
import { LoadingIndicator } from "./components/LoadingIndicator/LoadingIndicator";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";

import cherryLogo from "assets/cherryhub_logo.svg";
import { BusinessConfig } from "./pages/BusinessConfig/BusinessConfig";
import { useLayoutContext } from "./context/LayoutContext";
import { TermsConditionsList } from "./pages/TermsConditionsList/TermsConditionsList";
import { TermsConditionsPage } from "./components/TermsConditionsPage/TermsConditionsPage";
import { TermsConditionsView } from "./components/TermsConditionsView/TermsConditionsView";

const AppLayout = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { isDesktop } = useLayoutContext();

  return (
    <Layout location={location.pathname}>
      <Layout.Title>
        <HStack>
          <AppTitle />
          {isDesktop && (
            <Box>
              <CurrentUser />
            </Box>
          )}
        </HStack>
      </Layout.Title>
      <Layout.Menu>
        {!isDesktop && (
          <VStack w="100%" bg="cherryUi.600" padding="2" marginBottom="2">
            <Image src={cherryLogo} boxSize="100px" title="Chery hub" />
            <CurrentUser />
          </VStack>
        )}
        {!!user && (
          <VStack spacing="2" w="100%">
            <AppMenu isDesktop={isDesktop} />
          </VStack>
        )}
      </Layout.Menu>
      <Layout.Content>
        <ErrorHandler
          renderErrorState={() => (
            <ErrorMessage>There was an error loading the page.</ErrorMessage>
          )}
        >
          <ModelContextProvider>
            <Outlet />
          </ModelContextProvider>
        </ErrorHandler>
      </Layout.Content>
    </Layout>
  );
};

const AppLayoutGuest = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { isDesktop } = useLayoutContext();

  return (
    <Layout location={location.pathname}>
      <Layout.Title>
        <HStack>
          <AppTitle />
          {isDesktop && (
            <Box>
              <CurrentUser />
            </Box>
          )}
        </HStack>
      </Layout.Title>
      <Layout.Menu>
        {!isDesktop && (
          <VStack w="100%" bg="cherryUi.600" padding="2" marginBottom="2">
            <Image src={cherryLogo} boxSize="100px" title="Chery hub" />
            <CurrentUser />
          </VStack>
        )}
        {!!user && (
          <VStack spacing="2" w="100%">
            <AppMenu isDesktop={isDesktop} />
          </VStack>
        )}
      </Layout.Menu>
      <Layout.Content>
        <ErrorHandler
          renderErrorState={() => (
            <ErrorMessage>There was an error loading the page.</ErrorMessage>
          )}
        >
          <ModelContextProvider>
            <Outlet />
          </ModelContextProvider>
        </ErrorHandler>
      </Layout.Content>
    </Layout>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, userBusinessId, isLoading } = useUserContext();
  const { businessId } = useParams();
  const navigate = useNavigate();

  // If the user is not authorised to view the current business, redirect them to their business.
  useEffect(() => {
    if (
      !isLoading &&
      userBusinessId &&
      userBusinessId !== "*" &&
      userBusinessId !== businessId
    ) {
      navigate(`/businesses/${userBusinessId}`);
    }
  }, [businessId, userBusinessId, isLoading, navigate]);

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!user) {
    return <Login />;
  }

  return children;
};

const LoginRoute = () => {
  const navigate = useNavigate();
  const onLoginSuccess = useCallback(
    (businessId?: string) => {
      navigate(
        businessId !== "*" ? `/businesses/${businessId}` : "/businesses"
      );
    },
    [navigate]
  );
  return <Login onLoginSuccess={onLoginSuccess} />;
};

export const AppRoutes = () => (
  <Routes>
    <Route
      element={
        <RequireAuth>
          <AppLayout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<Navigate to="/businesses" />} />
      <Route path="/businesses" element={<BusinessList />} />
      <Route path="/businesses/:businessId" element={<Business />} />
      <Route
        path="/businesses/:businessId/members/:memberId"
        element={<MemberDetail />}
      />
      {/* <Route path="/businesses/:businessId/roles" element={<BusinessRoles />} /> */}
      <Route path="/businesses/:businessId/users" element={<Users />} />
      <Route
        path="/businesses/:businessId/configuration"
        element={<BusinessConfig />}
      />
      <Route
        path="/businesses/:businessId/terms-conditions/new"
        element={<TermsConditionsPage />}
      />

      <Route
        path="/businesses/:businessId/terms-conditions/:termsId"
        element={<TermsConditionsPage />}
      />
      <Route
        path="/businesses/:businessId/terms-conditions"
        element={<TermsConditionsList />}
      />
    </Route>
    <Route path="/login" element={<LoginRoute />} />
    <Route
    // element={
    //   <Box>
    //     <AppLayoutGuest />
    //   </Box>
    // }
    >
      <Route
        path="view/:businessId/terms-conditions/:termsId"
        element={<TermsConditionsView />}
      />
    </Route>
  </Routes>
);
