import { useCallback, useEffect, useMemo, useState } from "react";
import { CherryPayApi } from "../api/models";
import { useApiRequest } from "../hooks/useApiRequest";
import { roleListDiff } from "../util/roleDiff";

export const useRoles = (business: CherryPayApi.BusinessSummary) => {
  const [roles, setRoles] = useState<CherryPayApi.Role[]>([]);

  const getRolesRequest = useApiRequest(
    (apiClient) => apiClient.getBusinessRoles(business.BusinessId),
    [business]
  );

  useEffect(
    () => setRoles(getRolesRequest.data ?? []),
    [getRolesRequest.data, setRoles]
  );

  /**
   * Reverts any local changes
   */
  const resetRoles = useCallback(() => {
    setRoles(getRolesRequest.data ?? []);
  }, [getRolesRequest.data, setRoles]);

  /**
   * Merges an updated role with the role list. role.Name is used as the key.
   */
  const updateRole = useCallback(
    (updatedRole: CherryPayApi.Role) => {
      setRoles((state) =>
        state.map((role) =>
          updatedRole.Name === role.Name && !role.IsApplicationDefault
            ? updatedRole
            : role
        )
      );
    },
    [setRoles]
  );

  /**
   * Removes a role from the list.
   */
  const removeRole = useCallback(
    (toRemove: CherryPayApi.Role) => {
      setRoles((state) => state.filter((role) => role.Name !== toRemove.Name));
    },
    [setRoles]
  );

  /**
   * Appends a role to the end of the roles
   */
  const addRole = useCallback(
    (newRole: CherryPayApi.Role) => {
      setRoles((state) => state.concat([newRole]));
    },
    [setRoles]
  );

  const roleDiff = useMemo(
    () => roleListDiff(getRolesRequest.data ?? [], roles),
    [getRolesRequest.data, roles]
  );

  return {
    ...getRolesRequest,
    roles,
    updateRole,
    addRole,
    removeRole,
    roleDiff,
    resetRoles,
  };
};
