import { useMemo } from "react";
import { Permission, useUserContext } from "../context/UserContext";

export const usePermissions = <T extends Permission[]>(
  ...permissionsToCheck: T
): boolean[] => {
  const { isSuperAdmin, permissions } = useUserContext();

  return useMemo(() => {
    return permissionsToCheck.map((permission) =>
      isSuperAdmin ? true : permissions?.has(permission)
    );
  }, [...permissionsToCheck, isSuperAdmin, permissions]);
};
