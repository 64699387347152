import { useMediaQuery } from "@chakra-ui/react";
import React, { createContext, useContext, useMemo } from "react";

interface LayoutContextProviderProps {
  forceDesktopLayout?: boolean;
  children: React.ReactNode;
}

interface LayoutContext {
  isDesktop: boolean;
}

const LayoutContext = createContext<LayoutContext>({ isDesktop: false });

export const LayoutContextProvider = ({
  children,
  forceDesktopLayout,
}: LayoutContextProviderProps) => {
  const [isDesktop] = useMediaQuery(["(min-width: 1000px)"]);

  const ctx: LayoutContext = useMemo(
    () => ({
      isDesktop: forceDesktopLayout ?? isDesktop,
    }),
    [isDesktop, forceDesktopLayout]
  );
  return (
    <LayoutContext.Provider value={ctx}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};
