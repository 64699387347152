import React, { memo } from "react";
import { format } from "date-fns";
import { DeleteIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/react";
import { Table, Tbody, Td, Th, Tr, Thead } from "@chakra-ui/table";
import { Tag } from "@chakra-ui/tag";
import { CherryPayApi } from "../../api/models";
import { EditIcon } from "../../styles/icons";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

interface VenuesTableProps {
  venues: CherryPayApi.VenueDocument[] | null;
  isLoading: boolean;
  disableActions?: boolean;
  onClickEditVenue: (venue: CherryPayApi.VenueDocument) => void;
  onClickDeleteVenue: (venue: CherryPayApi.VenueDocument) => void;
}

export const VenuesTable = memo(
  ({
    venues,
    isLoading,
    disableActions,
    onClickEditVenue,
    onClickDeleteVenue,
  }: VenuesTableProps) => {
    return (
      <Box w="100%" overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th width="0.1%" whiteSpace="nowrap">
                Venue #
              </Th>
              <Th>Name</Th>
              <Th>Enabled</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {(venues ?? []).map((venue) => (
              <Tr key={venue.id}>
                <Td textAlign="center" width="0.1%" whiteSpace="nowrap">
                  {venue.VenueNo}
                </Td>
                <Td>
                  {!!venue._Meta?.DateDeleted && (
                    <Tag size="sm" mr="2">
                      Deleted
                    </Tag>
                  )}
                  {venue.DisplayName}
                </Td>
                <Td width="0.1%" whiteSpace="nowrap">
                  {venue.Enabled ? "Yes" : "No"}
                </Td>
                <Td width="0.1%" whiteSpace="nowrap">
                  <IconButton
                    aria-label="Edit venue."
                    title="Edit venue."
                    icon={<EditIcon />}
                    size="sm"
                    isDisabled={disableActions}
                    onClick={() => onClickEditVenue(venue)}
                  />
                  {!venue._Meta?.DateDeleted && (
                    <IconButton
                      marginLeft="1"
                      aria-label="Delete venue."
                      title="Delete venue."
                      icon={<DeleteIcon />}
                      size="sm"
                      isDisabled={disableActions}
                      onClick={() => onClickDeleteVenue(venue)}
                    />
                  )}
                </Td>
              </Tr>
            ))}
            {venues?.length === 0 && !isLoading && (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  No Venues.
                </Td>
              </Tr>
            )}
            {!venues && isLoading && (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  <LoadingIndicator color="gray.600" />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    );
  }
);
