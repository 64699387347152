import { getColor, mode } from "@chakra-ui/theme-tools";

function getDefaults(props: any) {
  var { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc ?? "blue.500",
    errorBorderColor: ec ?? "red.500",
  };
}

export const Input = {
  variants: {
    outline: (props: any) => {
      var { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
      return {
        field: {
          border: "1px solid",
          borderColor: "inherit",
          bg: "inherit",
          _hover: {
            borderColor: "gray.300",
          },
          _readOnly: {
            boxShadow: "none !important",
            userSelect: "all",
          },
          _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
          },
          _invalid: {
            borderColor: getColor(props.theme, ec),
            boxShadow: "0 0 0 1px " + getColor(props.theme, ec),
          },
          _focus: {
            zIndex: 1,
            borderColor: getColor(props.theme, fc),
            boxShadow: "0 0 0 1px " + getColor(props.theme, fc),
          },
          _placeholder: {
            color: "gray.400",
          },
        },
        addon: {
          border: "1px solid",
          borderColor: mode("inherit", "whiteAlpha.50")(props),
          bg: mode("gray.100", "whiteAlpha.300")(props),
        },
      };
    },
  },
};
