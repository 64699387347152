import React, { useCallback, useEffect, useRef, useState } from "react";
import { Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Avatar, HStack, VStack } from "@chakra-ui/react";
import { CherryPayApi } from "../../api/models";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiClient } from "../../hooks/useApiClient";
import { EditUserForm } from "../../forms/EditUserForm";
import { NewUserForm } from "../../forms/NewUserForm";
import { useToast } from "../../hooks/useToast";

interface UserModalProps {
  /** If an existing user is passed in the modal will open in edit mode */
  existingUser?: CherryPayApi.UserAccount | null;

  isOpen: boolean;

  onClose: () => void;

  availableRoles: CherryPayApi.Role[];
}

const UserInfo = ({
  name,
  uid,
  email,
}: {
  name?: string | null;
  uid: string;
  email?: string | null;
}) => (
  <HStack w="100%" alignItems="start">
    <Avatar name={name ?? email ?? "?"} />
    <VStack flex="1" justifyContent="start" spacing="0">
      {name && (
        <Text fontWeight="bold" w="100%">
          {name}
        </Text>
      )}
      {email && <Text w="100%">{email}</Text>}
    </VStack>
  </HStack>
);

export const UserModal = ({
  isOpen,
  onClose,
  existingUser: existingUserProp,
  availableRoles,
}: UserModalProps) => {
  const [updatedUser, setUpdatedUser] =
    useState<CherryPayApi.UserAccount | null>(null);

  const initialRef = useRef<HTMLInputElement>(null);
  const apiClient = useApiClient();

  const business = useBusinessContext();

  const { showSuccessToast, showErrorToast } = useToast();

  const createSuccessToast = useCallback(
    () => showSuccessToast("User created"),
    [showSuccessToast]
  );

  const createFailureToast = useCallback(
    (message?: string) => showErrorToast(message ?? "User creation failed."),
    [showErrorToast]
  );

  const editSuccessToast = useCallback(
    () => showSuccessToast("User updated"),
    [showSuccessToast]
  );

  const editFailureToast = useCallback(
    (message?: string) => showErrorToast(message ?? "User update failed."),
    [showErrorToast]
  );

  const onEditSuccess = useCallback(() => {
    editSuccessToast();
    onClose();
  }, [editSuccessToast, onClose]);

  const onCreateSuccess = useCallback(
    (user: CherryPayApi.UserAccount) => {
      createSuccessToast();
      setUpdatedUser(user);
    },
    [editSuccessToast, setUpdatedUser]
  );

  // Clean up state between modal openings
  useEffect(() => setUpdatedUser(null), [isOpen, setUpdatedUser]);

  const existingUser = updatedUser ?? existingUserProp;

  let modalContent: JSX.Element | null = null;

  if (existingUser) {
    modalContent = (
      <VStack w="100%" spacing="2">
        <UserInfo
          uid={existingUser.Uid}
          name={existingUser.DisplayName}
          email={existingUser.Email}
        />
        <EditUserForm
          apiClient={apiClient}
          business={business}
          businessId={business.BusinessId}
          user={existingUser}
          availableRoles={availableRoles}
          onCancel={onClose}
          onSuccess={onEditSuccess}
          onFailure={editFailureToast}
        />
      </VStack>
    );
  } else {
    modalContent = (
      <NewUserForm
        apiClient={apiClient}
        businessId={business.BusinessId}
        onCancel={onClose}
        onSuccess={onCreateSuccess}
        onFailure={createFailureToast}
      />
    );
  }

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {existingUser ?? updatedUser ? "Edit User" : "New User"}
        </ModalHeader>
        <ModalBody pb="6">{modalContent}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
