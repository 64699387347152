import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { Box, HStack, VStack, Heading, Text } from "@chakra-ui/layout";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/stat";
import React from "react";
import { Link } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { Card } from "../../components/Card/Card";
import { Members } from "../../components/Members/Members";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { BigNumber } from "bignumber.js";
import { usePermissions } from "../../hooks/usePermissions";
import { User } from "firebase/auth";

const VenueList = ({ venues }: { venues: CherryPayApi.VenueSummary[] }) => {
  return (
    <>
      {venues.map((venue) => {
        return (
          <Box width="100%" key={venue.VenueNo}>
            <HStack>
              <Stat>
                <StatLabel as="h3">Venue Name</StatLabel>
                <StatNumber color="cherryUi.600">
                  {venue.DisplayName}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Members</StatLabel>
                <StatNumber color="cherryUi.600">
                  {venue.MemberCount?.toLocaleString() ?? "-"}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Points Value</StatLabel>
                <StatNumber color="cherryUi.600">
                  $
                  {new BigNumber(
                    venue.TotalPointsBalanceDollarValue ?? 0
                  ).toFormat(2)}
                </StatNumber>
              </Stat>
            </HStack>
          </Box>
        );
      })}
    </>
  );
};

const BusinessSummary = ({
  business,
  user,
}: {
  business: CherryPayApi.BusinessSummary;
  user: User | null;
}) => {
  return (
    <Card w="100%" minH="500px">
      <VStack w="100%" spacing="2" alignItems="start">
        <Text>Welcome, {user?.displayName ?? user?.email}</Text>
        <Heading>{business.DisplayName}</Heading>
        <Text>ABN: {business.BusinessId}</Text>
      </VStack>
    </Card>
  );
};

export const Business = () => {
  const { userBusinessId, user } = useUserContext();
  const business = useBusinessContext();

  const [canViewMembers] = usePermissions("Member.Search");

  const pageTitle = "";

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Link}
                to={`/businesses/${business.BusinessId}`}
              >
                {business.DisplayName}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
        </Breadcrumb>
        <PageHeading.Title>{pageTitle}&nbsp;</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <BusinessSummary user={user} business={business} />
      </PageContent>
    </>
  );
};
