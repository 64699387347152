import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from "@chakra-ui/react";
import React from "react";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface CurrencyFieldProps extends BaseFieldProps {}

const parseValue = (value: string) => {
  const parsed = value.replace(/\$/g, "");

  if (parsed === "") {
    return "";
  }

  return parsed;
};

export const CurrencyField = ({
  name,
  label,
  id,
  ...numberInputProps
}: CurrencyFieldProps & NumberInputProps) => {
  return (
    <FieldWrapper name={name} id={id} label={label}>
      {({ field, form }) => {
        const value = field.value === "" ? "" : `\$${field.value}`;
        return (
          <NumberInput
            precision={2}
            value={value}
            onChange={(strValue) => {
              form.setFieldValue(field.name, parseValue(strValue), true);
            }}
            onBlur={field.onBlur}
            name={field.name}
            pattern="(\$)?[0-9]*(.[0-9]+)?"
            {...numberInputProps}
          >
            <NumberInputField placeholder={numberInputProps.placeholder} />
          </NumberInput>
        );
      }}
    </FieldWrapper>
  );
};
