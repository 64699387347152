import { Textarea, TextareaProps } from "@chakra-ui/textarea";
import React from "react";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface TextAreaProps extends BaseFieldProps {}

export const TextArea = ({
  name,
  label,
  id,
  helperText,
  ...textAreaProps
}: TextAreaProps & TextareaProps) => {
  return (
    <FieldWrapper
      inputProps={{ isRequired: textAreaProps.isRequired ?? false }}
      name={name}
      id={id}
      label={label}
      helperText={helperText}
    >
      {({ field }) => <Textarea {...field} {...textAreaProps} />}
    </FieldWrapper>
  );
};
