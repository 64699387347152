import React, { createContext, useContext, useMemo } from "react";

import { initializeApp, FirebaseApp } from "firebase/app";
import { getAnalytics, Analytics } from "firebase/analytics";
import { getAuth, Auth, User } from "firebase/auth";
import { getFirestore, Firestore } from "firebase/firestore";

export interface FirebaseContext {
  app: FirebaseApp;
  analytics: Analytics;
  auth: Auth;
  db: Firestore;
}

export const FirebaseContext = createContext<FirebaseContext | null>(null);

interface FirebaseProviderProps {
  children: React.ReactNode;
}

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const ctx = useMemo(() => {
    if (!FIREBASE_CONFIG) {
      throw Error("Missing firebase config.");
    }
    const app = initializeApp(FIREBASE_CONFIG);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);

    const database = getFirestore(app);

    return {
      app,
      analytics,
      auth,
      db: database,
    };
  }, []);

  return (
    <FirebaseContext.Provider value={ctx}>{children}</FirebaseContext.Provider>
  );
};

export const useFirebase = (): FirebaseContext => {
  const ctx = useContext(FirebaseContext);

  if (!ctx) {
    throw new Error("Missing firebase context");
  }

  return ctx;
};
