export const Table = {
  baseStyle: {
    table: {
      fontVariantNumeric: "lining-nums tabular-nums",
      borderCollapse: "collapse",
      width: "full",
      borderRadius: "10px",
      overflow: "hidden",
      shadow: "md",
      backgroundColor: "#fff",
    },
    th: {
      fontFamily: "heading",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "wider",
      textAlign: "start",
      backgroundColor: "#eeeeee",
      paddingTop: "20px",
    },
    td: {
      textAlign: "start",
    },
    caption: {
      mt: 4,
      fontFamily: "heading",
      textAlign: "center",
      fontWeight: "medium",
    },
  },
  sizes: {
    sm: {
      th: {
        px: "4",
        py: "1",
        lineHeight: "8",
        fontSize: "10px",
      },
      td: {
        px: "4",
        py: "2",
        fontSize: "sm",
        lineHeight: "4",
      },
      caption: {
        px: "4",
        py: "2",
        fontSize: "xs",
      },
    },
    md: {
      th: {
        px: "6",
        py: "3",
        lineHeight: "8",
        fontSize: "xs",
      },
      td: {
        px: "6",
        py: "4",
        lineHeight: "5",
      },
      caption: {
        px: "6",
        py: "2",
        fontSize: "sm",
      },
    },
    lg: {
      th: {
        px: "8",
        py: "4",
        lineHeight: "5",
        fontSize: "sm",
      },
      td: {
        px: "8",
        py: "5",
        lineHeight: "6",
      },
      caption: {
        px: "6",
        py: "2",
        fontSize: "md",
      },
    },
  },
  variants: {
    simple: {
      th: {
        color: "gray.600",
        borderBottom: "1px",
        borderColor: "gray.100",
      },
      td: {
        borderBottom: "1px",
        borderColor: "gray.100",
      },
      caption: {
        color: "gray.600",
      },
      tfoot: {
        tr: {
          "&:last-of-type": {
            th: {
              borderBottomWidth: 0,
            },
          },
        },
      },
    },
  },
};
