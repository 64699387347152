import { Avatar } from "@chakra-ui/avatar";
import { BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Text, VStack, Heading, HStack, Wrap } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";

import { Stat, StatLabel, StatNumber } from "@chakra-ui/stat";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { PointsRequestModal } from "../../components/PointsRequestModal/PointsRequestModal";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Card } from "../../components/Card/Card";
import { InvitationModal } from "../../components/InvitationModal/InvitationModal";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import {
  useBusinessContext,
  useMemberContext,
} from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useModalState } from "../../hooks/useModalState";
import {
  CardIcon,
  PlusIcon,
  PhoneIcon,
  TransferIcon,
  MoreHorizontal,
  UserIcon,
  ZapIcon,
} from "../../styles/icons";
import { BigNumber } from "bignumber.js";
import { TransferPointsModal } from "../../components/TransferPointsModal/TransferPointsModal";
import { CherryPayCardModal } from "../../components/CherryPayCardModal/CherryPayCardModal";
import { usePermissions } from "../../hooks/usePermissions";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";

const StatRow = ({
  stats,
}: {
  stats: { label: string; value: string; isLoading?: boolean }[];
}) => (
  <HStack w="100%">
    {stats.map(({ label, value, isLoading }) => (
      <Stat key={label}>
        <StatLabel>{isLoading ? "Loading..." : label}</StatLabel>
        <StatNumber color="cherryUi.600">
          {isLoading ? <Spinner color="#344" /> : value}
        </StatNumber>
      </Stat>
    ))}
  </HStack>
);

const memberContactDetails = (member?: CherryPayApi.Member) => {
  if (!member) {
    return null;
  }
  switch (member.DefaultContactDetails) {
    case "Postal":
      return member.PostalContactDetails;
    case "Work":
      return member.WorkContactDetails;
    case "Home":
    default:
      return member.HomeContactDetails;
  }
};

export const MemberDetail = () => {
  const business = useBusinessContext();
  const member = useMemberContext();

  const businessId = business.BusinessId;
  const memberId = member.id;
  const pointsBalanceRequest = useApiRequest(
    (apiClient) => apiClient.getDefaultPointsBalance(businessId, memberId),
    [businessId, memberId]
  );

  const [
    canAwardPoints,
    canInviteMemberToCard,
    canInviteMemberToApp,
    canTransferPointsToMoney,
  ] = usePermissions(
    "Loyalty.CreditPoints",
    "Member.InviteToCard",
    "Member.InviteToApp",
    "Cardholder.PointsToMoney"
  );
  const memberStats: { label: string; value: string; isLoading?: boolean }[] =
    useMemo(() => {
      const pointsBalanceError =
        !pointsBalanceRequest.isLoading && !pointsBalanceRequest.data;
      return [
        {
          label: pointsBalanceError
            ? "Points Balance"
            : `${pointsBalanceRequest.data?.DisplayName ?? ""} Points Value`,
          value: pointsBalanceError
            ? "Error"
            : `$${new BigNumber(
                pointsBalanceRequest.data?.PointsBalanceDollarValue ?? 0
              ).toFormat(2)}`,
          isLoading: pointsBalanceRequest.isLoading,
        },
        {
          label: "Member Level",
          value: member?.MemberLevelDescription ?? "-",
        },
        {
          label: "Status",
          value: member?.Status ?? "-",
        },
      ];
    }, [member, pointsBalanceRequest]);

  const memberFullName = `${member?.PreferredName ?? member?.FirstName} ${
    member?.LastName
  }`;

  const email = memberContactDetails(member)?.EmailAddress;
  const phone = memberContactDetails(member)?.MobileNumber;

  const addPointsModalState = useModalState<{
    memberId: string;
    type: "add-points" | "transfer-points-to-cpc";
  }>();
  const invitationModalState = useModalState<{
    memberId: string;
    type: "digital-membership" | "cherry-pay-card";
    cpcType?: "instantgift" | "reloadable";
  }>();

  // Refresh the points balance when closing the add points podal.
  useModalCloseEffect(addPointsModalState, () => pointsBalanceRequest.refresh, [
    pointsBalanceRequest.refresh,
  ]);

  const onClickAddPoints = useCallback(
    () => addPointsModalState.onOpen({ memberId, type: "add-points" }),
    [addPointsModalState.onOpen, memberId]
  );

  const onClickTransferPoints = useCallback(
    () =>
      addPointsModalState.onOpen({ memberId, type: "transfer-points-to-cpc" }),
    [addPointsModalState.onOpen, memberId]
  );

  const onClickDMCInvite = useCallback(
    () => invitationModalState.onOpen({ memberId, type: "digital-membership" }),
    [invitationModalState.onOpen, memberId]
  );

  const onClickCPCInstantGift = useCallback(
    () =>
      invitationModalState.onOpen({
        memberId,
        type: "cherry-pay-card",
        cpcType: "instantgift",
      }),
    [invitationModalState.onOpen, memberId]
  );

  const onClickCPCReloadable = useCallback(
    () =>
      invitationModalState.onOpen({
        memberId,
        type: "cherry-pay-card",
        cpcType: "reloadable",
      }),
    [invitationModalState.onOpen, memberId]
  );

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${businessId}`}>
              Members
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${businessId}/members/${memberId}`}
            >
              {memberFullName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>View Member</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card w="100%">
          <VStack w="100%" padding="2" spacing="2">
            <HStack w="100%" spacing="2" alignItems="start">
              <Avatar
                borderRadius="full"
                boxSize="60px"
                name={memberFullName}
              />
              <VStack flex="1" spacing="1">
                <Heading as="h2" size="md" w="100%">
                  {memberFullName}
                </Heading>
                {email && <Text w="100%">{email}</Text>}
                {phone && <Text w="100%">{phone}</Text>}
              </VStack>
              <HStack flex="1" justifyContent="end">
                <Menu>
                  <MenuButton
                    leftIcon={<ZapIcon />}
                    colorScheme="cherryButton"
                    as={Button}
                    aria-label="Member Actions"
                  >
                    Member Actions
                  </MenuButton>
                  <MenuList>
                    {canInviteMemberToApp && (
                      <MenuItem onClick={onClickDMCInvite} icon={<CardIcon />}>
                        Digital Membership Card
                      </MenuItem>
                    )}
                    {canInviteMemberToCard && (
                      <>
                        <MenuItem
                          onClick={onClickCPCInstantGift}
                          icon={<CardIcon />}
                        >
                          CherryPay Instant Gift Card
                        </MenuItem>
                        <MenuItem
                          onClick={onClickCPCReloadable}
                          icon={<CardIcon />}
                        >
                          CherryPay Reloadable Card
                        </MenuItem>
                      </>
                    )}
                    {canTransferPointsToMoney && (
                      <MenuItem
                        onClick={onClickTransferPoints}
                        icon={<TransferIcon />}
                      >
                        Transfer Points
                      </MenuItem>
                    )}
                    {canAwardPoints && (
                      <MenuItem onClick={onClickAddPoints} icon={<PlusIcon />}>
                        Award Points
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
            <StatRow stats={memberStats} />
          </VStack>
        </Card>
      </PageContent>

      <TransferPointsModal
        isOpen={
          addPointsModalState.isOpen &&
          addPointsModalState.opts?.type === "transfer-points-to-cpc"
        }
        memberId={addPointsModalState.opts?.memberId}
        onClose={addPointsModalState.onClose}
      />

      <PointsRequestModal
        isOpen={
          addPointsModalState.isOpen &&
          addPointsModalState.opts?.type === "add-points"
        }
        memberId={addPointsModalState.opts?.memberId}
        pointsRequestType="add-points"
        onClose={addPointsModalState.onClose}
      />

      <InvitationModal
        isOpen={
          invitationModalState.isOpen &&
          invitationModalState.opts?.type === "digital-membership"
        }
        memberId={invitationModalState.opts?.memberId}
        invitationType={invitationModalState.opts?.type}
        onClose={invitationModalState.onClose}
      />

      <CherryPayCardModal
        isOpen={
          invitationModalState.isOpen &&
          invitationModalState.opts?.type === "cherry-pay-card"
        }
        cpcType={invitationModalState.opts?.cpcType ?? "instantgift"}
        memberId={invitationModalState.opts?.memberId}
        onClose={invitationModalState.onClose}
      />
    </>
  );
};
