import { extendTheme } from "@chakra-ui/react";
import { ThemeConfig, theme as baseTheme } from "@chakra-ui/theme";
import { Button } from "./button/button";
import { Table } from "./table/table";
import { Input } from "./input/input";

// Import fonts
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/fredoka-one/400.css";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      "html, body": {
        margin: 0,
        padding: 0,
        color: "gray.600",
        backgroundColor: "cherryUi.800",
        overflowX: "hidden",
      },
      "h1, h2, h3, h4, h5, h6": {
        color: props.colorMode === "light" ? "gray.600" : "white",
      },
    }),
  },
  colors: {
    black: "#000",
    white: "#fff",
    // Color scheme for cherryhub buttons
    cherryButton: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#ee3443", // bg
      600: "#bb2834", // hoverBg
      700: "#6e171e", // activeBg
    },
    cherryButtonDark: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#9e1029", // bg
      600: "#6b0a1b", // hoverBg
      700: "#510815", // activeBg
    },
    cherryUi: {
      600: "#c41836",
      700: "#9e1029",
      800: "#7a121e",
    },
  },
  fonts: {
    heading: "Poppins, Helvetica, sans-serif",
    body: "Poppins, Helvetica, sans-serif",
  },
  components: {
    Button,
    Table,
    Input,
    Checkbox: {
      defaultProps: {
        colorScheme: "cherryButton",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "cherryButton",
      },
    },
    Header: {
      baseStyle: {
        fontWeight: "600",
      },
      variants: {
        content: {
          color: "blue",
        },
      },
    },
    Modal: {
      baseStyle: {
        header: {
          backgroundColor: "cherryUi.600",
          color: "#fff",
          borderTopRightRadius: "var(--chakra-radii-md)",
          borderTopLeftRadius: "var(--chakra-radii-md)",
        },
        body: {
          paddingTop: "var(--chakra-space-6)",
        },
        closeButton: {
          color: "#fff",
        },
      },
    },
  },
});
