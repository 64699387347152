import React, { useCallback, useState } from "react";
import { useDebouncedValue } from "./useDebouncedValue";

export const useSearchInputState = (defaultValue?: string, waitMs?: number) => {
  const [query, setQuery] = useState(defaultValue ?? "");
  const debouncedQuery = useDebouncedValue(query, waitMs ?? 400);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value),
    [setQuery]
  );

  return {
    query,
    debouncedQuery,
    onChange,
    setQuery,
    isDebouncing: query !== debouncedQuery,
  };
};
