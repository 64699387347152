import { useCallback, useState } from "react";

export const useCheckboxState = (defaultState: boolean) => {
  const [checked, setChecked] = useState<boolean>(defaultState ?? false);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(e.currentTarget.checked);
    },
    [setChecked]
  );
  return { checked, onChange };
};
