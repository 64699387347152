import React, { useEffect, useMemo } from "react";
import {
  Flex,
  Text,
  Heading,
  useBoolean,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from "@chakra-ui/react";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  FormikErrors,
  FormikProps,
  withFormik,
} from "formik";
import { Button } from "@chakra-ui/button";
import {
  Box,
  HStack,
  VStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/layout";

import { ApiClient, ApiResult } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { TextField } from "../components/fields/TextField/TextField";
import { SwitchField } from "../components/fields/SwitchField/SwitchField";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { PlusIcon, TrashIcon } from "../styles/icons";
import { Checkbox } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { isEmptyStr } from "../util/isEmptyStr";
import { FormStack } from "../components/FormStack/FormStack";

import {
  getFirestore,
  Firestore,
  addDoc,
  updateDoc,
  collection,
  query as fireQuery,
  orderBy,
  onSnapshot,
  DocumentReference,
} from "firebase/firestore";
import { SelectField } from "../components/fields/SelectField/SelectField";
import { IntegerField } from "../components/fields/IntegerField/IntegerField";
import { DateField } from "../components/fields/DateField/DateField";
import { TextArea } from "../components/fields/TextArea/TextArea";
import { FieldWrapper } from "../components/fields/FieldWrapper/FieldWrapper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaEllipsisV } from "react-icons/fa";

const emptyStrToNull = (value: string | null): string | null => {
  if (!value || value.trim() === "") {
    return null;
  } else {
    return value;
  }
};

const clauseContainerProps = {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "10px",
  w: "100%",
  className: "clauseContainer",
};

const clauseContainerHeadingProps = {
  fontSize: "1rem",
  mb: "15px",
};

const css = `.clauseContainer > * {
  margin-top: 10px;
}`;

export interface TermsConditionsFormValues {
  businessName?: string; // auto populated
  businessAbn?: string; // auto populated
  businessAddress?: string; // missing in the hub, so adding local field for now
  id?: string;
  name: string;
  gameType: string;
  eligibility: string[];
  eligibilityType: string;
  ageLimit: number;
  promoCommencement: Date;
  promoCommencementTz?: String;
  promoCommencementDayLight?: String;
  promoEnd: Date;
  promoEndTz?: String;
  promoEndDayLight?: String;
  entryMethod: string;
  thirdPartyPlatform: string;
  thirdPartyPlatformProvider: string;
  entryRestriction: string;
  entryRestrictionNumber: number;
  entryRestrictionPeriod?: string;
  typeOfWinner: string;
  notifyMethod: string;
  methodOfAcceptingPrize: string;
  determinationOfWinnerDesc: string;

  determinationMinorPrizeWinners?: string;
  determinationMajorPrizeWinners?: string;
  determinationDrawOutcome?: string;

  prizes: {
    id: string;
    type: string;
    name: string;
    rank: number;
  }[];
  totalPrizeValue: string;
  newPrizeId: string;
  newPrizeRank: number;
  newPrizeName: string;
  newPrizeType: string;
  deliveryOfPrize?: string;
  deliveryOfPrizeDetails: string;
  mediaPlatform: string;
  publishingDate: Date;
  claimTimePeriod: string;
  promoWinnerRestriction?: string;
  isPromoWinnerRestricted: boolean;
  isTimeLimitToUsePrize: boolean;
  isPrizeSuppliedBy3rdParty: boolean;
  prizeSupplier3rdPartyName: string;
  isIndemnityRequired: boolean;
  isProvideContent: boolean;
  privacyPolicyLink: string;
  nameOfPartyConductingPromotion: string;
  promoterEmail: string;
  "notifyMethod-SMS"?: boolean;
  "notifyMethod-Email"?: boolean;
  "notifyMethod-Phone"?: boolean;
  "notifyMethod-SocialMedia"?: boolean;
  "notifyMethod-Other"?: boolean;
  "eligibility-ALL"?: boolean;
  "eligibility-QLD"?: boolean;
  "eligibility-VIC"?: boolean;
  "eligibility-SA"?: boolean;
  "eligibility-WA"?: boolean;
  "eligibility-NT"?: boolean;
  "eligibility-ACT"?: boolean;
}

interface TermsConditionsFormProps {
  apiClient: ApiClient;
  db: Firestore;
  business: CherryPayApi.BusinessSummary;
  venue: any | null;
  isDisabled?: boolean;
  onCancel: () => void;
  onSuccess?: (venue?: any) => void;
  onFailure?: (message?: string) => void;
}

const InnerForm = ({
  isSubmitting,
  isDisabled,
  submitForm,
  isValid,
  onCancel,
  venue,
  values,
  setValues,
}: TermsConditionsFormProps & FormikProps<TermsConditionsFormValues>) => {
  const isNewVenue = !venue;
  const isDeletedVenue = !!venue?._Meta?.DateDeleted;
  values = values || {};
  const [isEntryMethodTemplatesShown, entryMethodTemplates] = useBoolean(false);
  const [isPrizeDeliveryTemplatesShown, prizeDeliveryTemplates] =
    useBoolean(false);
  const [
    isPromoWinnerRestrictionTemplatesShown,
    promoWinnerRestrictionTemplates,
  ] = useBoolean(false);

  return (
    <FormStack>
      <style>{css}</style>
      {!!venue && <TextField label="ID" name="id" isDisabled={true} />}

      <SelectField
        isRequired
        label="Game Type"
        name="gameType"
        options={[
          {
            label: "Game of Skill",
            value: "Game of Skill",
          },
          {
            label: "Game of Mixed Skill and Chance",
            value: "Game of Mixed Skill and Chance",
          },
        ]}
        allowEmpty={true}
      />

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 1</Heading>
        <TextField isRequired label="Name of Promotion" name="name" />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 2</Heading>
        <TextField isRequired label="Business Address" name="businessAddress" />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 3</Heading>

        <SelectField
          label="Eligibility Type"
          name="eligibilityType"
          options={[
            {
              label: "financial members of the Promoter",
              value: "financialMembers",
            },
            {
              label: "all permanent residents of nation or selected states",
              value: "all",
            },
          ]}
        />

        {values.eligibilityType === "all" && (
          <Box w="100%">
            <Flex w="100%" direction={["column", "column", "row"]}>
              <Text mr="20px" mb="10px">
                Eligibility
              </Text>
              <Box mr="20px">
                <SwitchField label="All" name="eligibility-ALL" />
              </Box>

              {values && values["eligibility-ALL"] !== true && (
                <Flex flex="1">
                  <SwitchField label="NSW" name="eligibility-NSW" />
                  <SwitchField label="QLD" name="eligibility-QLD" />
                  <SwitchField label="VIC" name="eligibility-VIC" />
                  <SwitchField label="SA" name="eligibility-SA" />
                  <SwitchField label="WA" name="eligibility-WA" />
                  <SwitchField label="NT" name="eligibility-NT" />
                  <SwitchField label="ACT" name="eligibility-ACT" />
                </Flex>
              )}
            </Flex>
          </Box>
        )}

        <IntegerField max={99} label="Age Limit" name="ageLimit" />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 4</Heading>
        <DateField
          type="datetime-local"
          label="Promotion Commencement"
          name="promoCommencement"
        />

        <TextField
          label="Promotion Commencement Timezone"
          name="promoCommencementTz"
          placeholder="AEST or AEDT"
        />

        <DateField
          type="datetime-local"
          label="Promotion End"
          name="promoEnd"
          isRequired
        />

        <TextField
          label="Promotion End Timezone"
          name="promoEndTz"
          placeholder="AEST or AEDT"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 5</Heading>
        <TextArea isRequired rows={5} label="Entry Method" name="entryMethod" />

        <Button
          onClick={() => entryMethodTemplates.toggle()}
          colorScheme={
            isEntryMethodTemplatesShown ? "cherryButtonDark" : undefined
          }
        >
          {isEntryMethodTemplatesShown ? "Hide Templates" : "Autofill Options"}
        </Button>
        {isEntryMethodTemplatesShown && (
          <Box pt="10px">
            <Text>
              <strong>
                Clicking on a template below will auto-populate the Entry Method
                field above:
              </strong>
            </Text>
            <Box>
              {[
                "Purchase is necessary/not necessary to win or participate in the Promotion.",
                "Entrants must follow the prompts via the invitation email/SMS/QR code/link to access the game link.",
                "Entrants agree to these Terms & Conditions in order to to complete an online entry form for access to the Leaderboard, which may include full name, contact mobile phone number, current and valid email address, membership number, date of birth, address.",
                "Entrants may play the Promotion game (as per Clause 7), during the Promotion Period.",
              ].map((template) => (
                <Box
                  my="10px"
                  onClick={() => {
                    setValues({
                      ...values,
                      entryMethod: template,
                    });
                  }}
                >
                  <Box
                    cursor="pointer"
                    bg="#f0f0f0"
                    borderRadius="5px"
                    border="1px solid #aaa"
                    padding="10px"
                  >
                    {template}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 6</Heading>
        <TextField label="3rd Party Platform" name="thirdPartyPlatform" />

        <TextField
          label="3rd Party Platform Provider"
          name="thirdPartyPlatformProvider"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 7</Heading>
        <SelectField
          label="Entry Restriction"
          name="entryRestriction"
          options={[
            {
              label: "No restriction on entries",
              value: "no",
            },
            {
              label: "Restriction on entries",
              value: "yes",
            },
          ]}
        />

        {values.entryRestriction === "yes" && (
          <IntegerField
            max={99}
            label="Maximum no of entries"
            name="entryRestrictionNumber"
          />
        )}

        {values.entryRestriction === "yes" && (
          <SelectField
            label="In Period"
            name="entryRestrictionPeriod"
            options={[
              {
                label: "Per day",
                value: " per day",
              },
              {
                label: "Whole Campaign",
                value: "",
              },
            ]}
          />
        )}
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 8</Heading>
        <SelectField
          label="Type of Winner"
          name="typeOfWinner"
          options={[
            {
              label: "One (1) winner (and one prize)",
              value: "1-1",
            },
            {
              label: "Multiple winners (and multiple prizes)",
              value: "n-n",
            },
            {
              label:
                "Multiple winners (and multiple prizes), being one major prize and multiple minor prizes",
              value: "1-n-n",
            },
          ]}
          allowEmpty={true}
        />

        {values.gameType.toLowerCase() !== "game of skill" && (
          <TextField
            label="Determination of Minor Prize Winners"
            name="determinationMinorPrizeWinners"
            helperText="E.g. every fifth entrant"
          />
        )}

        <TextField
          label="Determination of Major Prize Winners"
          name="determinationMajorPrizeWinners"
          helperText="E.g. highest five (5) scores"
        />

        <TextArea
          label="Determination of Draw Outcome"
          name="determinationDrawOutcome"
        />

        {/* <FieldWrapper
          name="determinationOfWinnerDesc"
          id="determinationOfWinnerDesc"
          label="Determination of Winner"
        >
          {({ field }) => (
            <>
              <ReactQuill
                theme="snow"
                value={field.value}
                defaultValue={field.value}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "list"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                  ],
                }}
                formats={[
                  "bold",
                  "italic",
                  "underline",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                ]}
                onChange={(val) =>
                  field.onChange({
                    target: {
                      name: field.name,
                      value: val,
                    },
                  })
                }
                // {...field}
                style={{ minHeight: "300px" }}
              />
            </>
          )}
        </FieldWrapper> */}
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 9</Heading>

        <Box w="100%">
          <Text mr="20px" mb="10px">
            Method of notifying winner
          </Text>
          <Flex w="100%" direction={["column", "column", "row"]}>
            <SwitchField label="SMS" name="notifyMethod-SMS" />
            <SwitchField label="Phone" name="notifyMethod-Phone" />
          </Flex>
          <Flex w="100%" direction={["column", "column", "row"]}>
            <SwitchField label="Email" name="notifyMethod-Email" />
            <SwitchField
              label="Social Media or similar"
              name="notifyMethod-SocialMedia"
            />
          </Flex>
          <Flex w="100%" direction={["column", "column", "row"]}>
            <SwitchField label="Any other means" name="notifyMethod-Other" />
          </Flex>
        </Box>

        {values["notifyMethod-Other"] === true && (
          <TextField
            label="Method of accepting the prize"
            name="methodOfAcceptingPrize"
          />
        )}
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 10</Heading>

        {/* <SelectField
          label="Delivery of prize"
          name="deliveryOfPrize"
          options={[
            {
              label: "Given to winner at a venue or similar",
              value: "inPerson",
            },
            {
              label:
                "Sent to a winner and the Promoter by email, post or similar",
              value: "byPost",
            },
          ]}
        /> */}

        {/* {values.deliveryOfPrize === "inPerson" && ( */}
        <TextArea
          isRequired
          rows={5}
          label="Delivery of Prize Details"
          name="deliveryOfPrizeDetails"
        />

        <Button
          onClick={() => prizeDeliveryTemplates.toggle()}
          colorScheme={
            isPrizeDeliveryTemplatesShown ? "cherryButtonDark" : undefined
          }
        >
          {isPrizeDeliveryTemplatesShown
            ? "Hide Templates"
            : "Autofill Options"}
        </Button>
        {isPrizeDeliveryTemplatesShown && (
          <Box pt="10px">
            <Text>
              <strong>
                Clicking on a template below will auto-populate the Entry Method
                field above:
              </strong>
            </Text>
            <Box>
              {(values.gameType.toLowerCase() !== "game of skill"
                ? [
                    "The Prize will be given to the Winner by the Promoter via a SMS with link to voucher, which must be presented at Reception to redeem the corresponding Minor or Major Prize.",
                    "The Prize will be given to the Winner by the Promoter by showing to a staff at the bar to redeem the corresponding Minor or Major Prize.",
                    "The Prize will be given to the Winner by the Promoter by scanning barcode in exchange for instant reward.",
                  ]
                : [
                    "The Prize will be given to the Winner by the Promoter via a SMS with link to voucher, which must be presented at Reception to redeem the corresponding Leaderboard Prize.",
                    "The Prize will be given to the Winner by the Promoter by showing to a staff at the bar to redeem the corresponding Leaderboard Prize.",
                    "The Prize will be given to the Winner by the Promoter by scanning barcode in exchange for instant reward.",
                  ]
              ).map((template) => (
                <Box
                  my="10px"
                  onClick={() => {
                    setValues({
                      ...values,
                      deliveryOfPrizeDetails: template,
                    });
                  }}
                >
                  <Box
                    cursor="pointer"
                    bg="#f0f0f0"
                    borderRadius="5px"
                    border="1px solid #aaa"
                    padding="10px"
                  >
                    {template}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {/* )} */}
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 11</Heading>
        <Text fontSize="90%">
          The Winner is usually published with the same media that was used to
          promote the campaign, ie Venue website, social media, in venue notice.
        </Text>
        <Box />
        <TextField label="Platform" name="mediaPlatform" />
        <DateField type="date" label="Publishing Date" name="publishingDate" />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 12</Heading>

        <PrizesList values={values} />

        <TextField
          label="Total Prize Value"
          name="totalPrizeValue"
          helperText="E.g. $2000"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 13</Heading>

        <TextField
          label="Time period within which the winner must claim the prize"
          name="claimTimePeriod"
          helperText="Time period should be reasonable, most often this is: 30 days"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 14</Heading>

        {/* <SwitchField
          label="Promotion winner restricted?"
          name="isPromoWinnerRestricted"
        /> */}

        <TextArea
          isRequired
          rows={5}
          label="Promo Restriction"
          name="promoWinnerRestriction"
        />

        <Button
          onClick={() => promoWinnerRestrictionTemplates.toggle()}
          colorScheme={
            isPromoWinnerRestrictionTemplatesShown
              ? "cherryButtonDark"
              : undefined
          }
        >
          {isPromoWinnerRestrictionTemplatesShown
            ? "Hide Templates"
            : "Autofill Options"}
        </Button>
        {isPromoWinnerRestrictionTemplatesShown && (
          <Box pt="10px">
            <Text>
              <strong>
                Clicking on a template below will auto-populate the Entry Method
                field above:
              </strong>
            </Text>
            <Box>
              {(values.gameType.toLowerCase() === "game of skill"
                ? [
                    "For the avoidance of doubt, Entrants are restricted from winning a Prize in more than one promotion conducted by, or in association with, the Promoter.",
                  ]
                : [
                    "Entrants are able to win one 1 Prize based on their Leaderboard position.",
                    "Entrants are able to win one 1 Prize based on their Leaderboard  position and one 1 Minor/Random Prize during the campaign.",
                    "Entrants are able to win one 1 Prize based on their Leaderboard position and up to one 1 of each Minor/Random Prize during the campaign.",
                  ]
              ).map((template) => (
                <Box
                  my="10px"
                  onClick={() => {
                    setValues({
                      ...values,
                      promoWinnerRestriction: template,
                    });
                  }}
                >
                  <Box
                    cursor="pointer"
                    bg="#f0f0f0"
                    borderRadius="5px"
                    border="1px solid #aaa"
                    padding="10px"
                  >
                    {template}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box>
          <Divider my="20px" />
        </Box>

        <SwitchField
          label="Time limit on use of the prize?"
          name="isTimeLimitToUsePrize"
        />

        <SwitchField
          label="Prize supplied by a third party?"
          name="isPrizeSuppliedBy3rdParty"
        />
        {values.isPrizeSuppliedBy3rdParty && (
          <TextField
            label="Name of 3rd party that is supplying the prize"
            name="prizeSupplier3rdPartyName"
          />
        )}

        <SwitchField
          label="Does indemnity or exclusion of liability need to be signed?"
          name="isIndemnityRequired"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 16</Heading>

        <SwitchField
          label="Provide content or create work?"
          name="isProvideContent"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 18</Heading>

        <TextField
          type="url"
          label="Add hyperlink to Privacy Policy"
          name="privacyPolicyLink"
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 19</Heading>

        <TextField
          label="Name(s) of 3rd party companies used for conducting promotion"
          name="nameOfPartyConductingPromotion"
          helperText="List one or more of these: Facebook, LinkedIn, Twitter, Instagram. Separate company names with commas. Leave blank if 3rd party is not used."
        />
      </Box>

      <Box {...clauseContainerProps}>
        <Heading {...clauseContainerHeadingProps}>Clause 20</Heading>

        <TextField
          isRequired
          label="Promoters contact email address"
          name="promoterEmail"
        />
      </Box>

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          colorScheme="cherryButton"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid || isDeletedVenue}
          onClick={submitForm}
        >
          Save
        </Button>

        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

const PrizesList = ({ values }: { values: TermsConditionsFormValues }) => {
  return (
    <FieldArray name="prizes">
      {(props) => {
        const isInvalid = !!props.form.errors.pointsTypes;
        return (
          <FormControl isInvalid={isInvalid}>
            <FormLabel>Prizes</FormLabel>
            <VStack
              border={isInvalid ? "1px solid red" : undefined}
              w="100%"
              padding="1"
            >
              <Table size="sm" mb="10px">
                <Thead>
                  <Tr>
                    <Th w="20%">Type</Th>
                    <Th>Name</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(values.typeOfWinner === "1-1"
                    ? values.prizes.length
                      ? [values.prizes[0]]
                      : []
                    : values.prizes
                  ).map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td w="20%" lineHeight="20px">
                          {item.type}
                        </Td>
                        <Td lineHeight="20px">{item.name}</Td>
                        <Td width="0.1%" whiteSpace="nowrap" textAlign="center">
                          <Menu>
                            <MenuButton as={Button} variant="unstyled">
                              <FaEllipsisV />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  // move up
                                  if (index === 0) {
                                    return;
                                  }
                                  props.swap(index, index - 1);
                                  // const myRank = props[index].rank;
                                  // props[index].rank = props[index-1].rank;
                                  // props[index-1].rank = myRank;
                                }}
                              >
                                Move Up
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  // move down
                                  if (index === values.prizes.length - 1) {
                                    return;
                                  }
                                  props.swap(index, index + 1);
                                }}
                              >
                                Move Down
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  props.remove(index);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>

                          {/* <IconButton
                            aria-label="Remove"
                            title="Remove"
                            icon={<TrashIcon />}
                            onClick={() => {
                              props.remove(index);
                            }}
                          /> */}
                        </Td>
                      </Tr>
                    );
                  })}
                  {values.prizes.length === 0 && (
                    <Tr>
                      <Td colSpan={4}>No Prizes Configured</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>

              {!(values.prizes.length && values.typeOfWinner === "1-1") && (
                <VStack w="100%">
                  <Box
                    w="100%"
                    boxShadow="0px 0px 15px 0px rgba(0,0,0,0.2)"
                    p="25px"
                    borderRadius="8px"
                  >
                    <Text mb="10px" fontWeight="bold">
                      Add New Prize
                    </Text>
                    <SelectField
                      marginBottom="10px"
                      isRequired
                      label="Prize Type"
                      name="newPrizeType"
                      options={[
                        {
                          label: "Major",
                          value: "Major",
                        },
                        {
                          label: "Minor",
                          value: "Minor",
                        },
                      ]}
                      allowEmpty={true}
                    />
                    <TextField
                      name={`newPrizeName`}
                      label="Prize Name"
                      placeholder=""
                    />
                  </Box>
                  <Button
                    leftIcon={<PlusIcon />}
                    isDisabled={
                      isEmptyStr(values.newPrizeName) ||
                      isEmptyStr(values.newPrizeType)
                    }
                    onClick={() => {
                      props.push({
                        id: Math.ceil(Math.random() * 1000000),
                        type: values.newPrizeType,
                        name: values.newPrizeName,
                        rank: values.prizes.length * 10,
                      });
                      // Clear the fields
                      props.form.setFieldValue("newPrizeId", "");
                      props.form.setFieldValue("newPrizeName", "");
                      props.form.setFieldValue("newPrizeType", "");
                      props.form.setFieldValue("newPrizeRank", 0);
                    }}
                  >
                    Add Prize
                  </Button>
                </VStack>
              )}
            </VStack>
            <FormErrorMessage>{props.form.errors.prizes}</FormErrorMessage>
          </FormControl>
        );
      }}
    </FieldArray>
  );
};

export const TermsConditionsForm = withFormik<
  TermsConditionsFormProps,
  TermsConditionsFormValues
>({
  mapPropsToValues: ({ venue, business }) => {
    return {
      businessName: business.DisplayName,
      businessAbn: business.BusinessId,
      businessAddress: venue?.businessAddress ?? "",
      id: venue?.id ?? undefined,
      name: venue?.name ?? "",
      gameType: venue?.gameType ?? "",
      eligibility: venue?.eligibility ?? "",
      eligibilityType: venue?.eligibilityType ?? "",
      ageLimit: venue?.ageLimit ?? "",
      promoCommencement: venue?.promoCommencement ?? "",
      promoCommencementTz: venue?.promoCommencementTz ?? "",
      promoEnd: venue?.promoEnd ?? "",
      promoEndTz: venue?.promoEndTz ?? "",
      entryMethod: venue?.entryMethod ?? "",
      thirdPartyPlatform: venue?.thirdPartyPlatform ?? "",
      thirdPartyPlatformProvider: venue?.thirdPartyPlatformProvider ?? "",
      entryRestriction: venue?.entryRestriction ?? "no",
      entryRestrictionNumber: venue?.entryRestrictionNumber ?? "",
      entryRestrictionPeriod: venue?.entryRestrictionPeriod ?? "",
      typeOfWinner: venue?.typeOfWinner ?? "",
      notifyMethod: venue?.notifyMethod ?? "",
      methodOfAcceptingPrize: venue?.methodOfAcceptingPrize ?? "",
      determinationOfWinnerDesc: venue?.determinationOfWinnerDesc ?? "",

      determinationMinorPrizeWinners:
        venue?.determinationMinorPrizeWinners ?? "",
      determinationMajorPrizeWinners:
        venue?.determinationMajorPrizeWinners ?? "",
      determinationDrawOutcome: venue?.determinationDrawOutcome ?? "",

      prizes: venue?.prizes ?? [],
      totalPrizeValue: venue?.totalPrizeValue ?? "",
      newPrizeId: "",
      newPrizeName: "",
      newPrizeType: "",
      newPrizeRank: 0,
      deliveryOfPrize: venue?.deliveryOfPrize ?? "inPerson",
      deliveryOfPrizeDetails: venue?.deliveryOfPrizeDetails ?? "",
      mediaPlatform: venue?.mediaPlatform ?? "",
      publishingDate: venue?.publishingDate ?? "",
      claimTimePeriod: venue?.claimTimePeriod ?? "",
      promoWinnerRestriction: venue?.promoWinnerRestriction ?? "",
      isPromoWinnerRestricted: venue?.isPromoWinnerRestricted ?? false,
      isTimeLimitToUsePrize: venue?.isTimeLimitToUsePrize ?? false,
      isPrizeSuppliedBy3rdParty: venue?.isPrizeSuppliedBy3rdParty ?? false,
      prizeSupplier3rdPartyName: venue?.prizeSupplier3rdPartyName ?? "",
      isIndemnityRequired: venue?.isIndemnityRequired ?? false,
      isProvideContent: venue?.isProvideContent ?? false,
      privacyPolicyLink: venue?.privacyPolicyLink ?? "",
      nameOfPartyConductingPromotion:
        venue?.nameOfPartyConductingPromotion ?? "",
      promoterEmail: venue?.promoterEmail ?? "",

      "notifyMethod-Email": (venue || {})["notifyMethod-Email"] ?? false,
      "notifyMethod-Phone": (venue || {})["notifyMethod-Phone"] ?? false,
      "notifyMethod-SocialMedia":
        (venue || {})["notifyMethod-SocialMedia"] ?? false,
      "notifyMethod-Other": (venue || {})["notifyMethod-Other"] ?? false,
      "eligibility-ALL": (venue || {})["eligibility-ALL"] ?? false,
      "eligibility-QLD": (venue || {})["eligibility-QLD"] ?? false,
      "eligibility-VIC": (venue || {})["eligibility-VIC"] ?? false,
      "eligibility-SA": (venue || {})["eligibility-SA"] ?? false,
      "eligibility-WA": (venue || {})["eligibility-WA"] ?? false,
      "eligibility-NT": (venue || {})["eligibility-NT"] ?? false,
      "eligibility-ACT": (venue || {})["eligibility-ACT"] ?? false,
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    if (isEmptyStr(values.name)) {
      setFieldError("name", "Title is required.");
      return;
    }

    console.log("values", values);

    // Translate form state to VenueDocument
    const venueDoc: Partial<any> = values;

    let result: DocumentReference;
    try {
      if (props.venue) {
        result = await props.apiClient.updateTermsConditions(
          props.db,
          props.business.BusinessId,
          props.venue?.id,
          venueDoc
        );
      } else {
        result = await props.apiClient.createTermsConditions(
          props.db,
          props.business.BusinessId,
          venueDoc
        );
      }

      if (props.onSuccess) {
        props.onSuccess(result);
      }

      console.log("res", result);
    } catch (e) {
      console.log("ress", e);
      if (props.onFailure) {
        props.onFailure((e as any).toString());
      }
    }
  },

  validateOnBlur: false,
  validateOnMount: false,
  validateOnChange: true,

  validate: (values) => {
    let errors: FormikErrors<TermsConditionsFormValues> = {};

    if (!values.name) {
      errors.name = "This is required";
    }

    if (!values.gameType) {
      errors.gameType = "This is required";
    }

    if (values.promoCommencement && !values.promoEnd) {
      errors.promoEnd = "Promotion End Date is required";
    }

    if (values.entryRestriction === "yes" && !values.entryRestrictionNumber) {
      errors.entryRestrictionNumber = "Enter a valid number";
    }

    if (
      values["notifyMethod-Other"] === true &&
      !values.methodOfAcceptingPrize
    ) {
      errors.methodOfAcceptingPrize = "This is required";
    }

    if (
      values.deliveryOfPrize === "inPerson" &&
      !values.deliveryOfPrizeDetails
    ) {
      errors.deliveryOfPrizeDetails = "This is required";
    }

    if (values.isPrizeSuppliedBy3rdParty && !values.prizeSupplier3rdPartyName) {
      errors.prizeSupplier3rdPartyName = "This is required";
    }

    if (!values.promoterEmail) {
      errors.promoterEmail = "This is required";
    }

    return errors;
  },
})(InnerForm);
