import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Field, FieldProps } from "formik";
import React, { useCallback, useMemo, useState } from "react";

export interface BaseFieldProps {
  name: string;
  label: string;
  helperText?: string;
  id?: string;
  inputProps?: {
    isRequired: boolean;
  };
}

export interface FieldWrapperProps extends BaseFieldProps {
  children: (props: FieldProps<any, any>) => JSX.Element;
}

const getNestedFormError = (errors: any, name: string) => {
  const nameParts = name.split(".");

  const error: string | undefined = nameParts.reduce((error, namePart) => {
    if (typeof error === "object" && error[namePart]) {
      return error[namePart];
    } else {
      if (typeof error === "string") {
        return error;
      } else {
        return undefined;
      }
    }
  }, errors);

  return error;
};

/** Provides label and error message rendering */
export const FieldWrapper = ({
  name,
  label,
  id: idProp,
  helperText,
  inputProps,
  children,
}: FieldWrapperProps) => {
  const id = idProp ?? `${name}-field`;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any, any>) => (
        <FormControl
          isRequired={inputProps?.isRequired}
          isInvalid={!!getNestedFormError(fieldProps.form.errors, name)}
        >
          <FormLabel htmlFor={id}>{label}</FormLabel>

          {children(fieldProps)}
          {!!helperText && (
            <FormHelperText marginBottom="2">{helperText}</FormHelperText>
          )}
          <FormErrorMessage>
            {getNestedFormError(fieldProps.form.errors, name)}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
