import { useCallback, useState } from "react";

interface ModalState<T> {
  opts: T | null;
  isOpen: boolean;
}
export const useModalState = <T>() => {
  const [state, setState] = useState<ModalState<T>>({
    isOpen: false,
    opts: null,
  });

  const onOpen = useCallback(
    (opts: T) => setState({ isOpen: true, opts: opts ?? null }),
    [setState]
  );

  const onClose = useCallback(
    () => setState({ isOpen: false, opts: null }),
    [setState]
  );

  return {
    onOpen,
    onClose,
    isOpen: state.isOpen,
    opts: state.opts,
  };
};
