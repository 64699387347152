import { Avatar, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { CherryPayApi } from "../../api/models";

export const MemberInfoBox = ({ member }: { member: CherryPayApi.Member }) => {
  const memberName = `${member.FirstName} ${member.LastName}`;
  return (
    <HStack>
      <Avatar name={memberName} />
      <VStack alignItems="start" spacing="0">
        <Text fontWeight="bold">{memberName}</Text>
        <Text>Member: {member.BadgeNumber}</Text>
      </VStack>
    </HStack>
  );
};
