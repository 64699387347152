import React, { useCallback, useEffect, useMemo } from "react";
import { Form, FormikErrors, FormikProps, withFormik } from "formik";
import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Link, Collapse, Alert, AlertIcon } from "@chakra-ui/react";
import { ApiClient, ApiResult } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { isEmptyStr } from "../util/isEmptyStr";
import { TextField } from "../components/fields/TextField/TextField";
import { MemberInfoBox } from "../components/MemberInfoBox/MemberInfoBox";
import { SelectField } from "../components/fields/SelectField/SelectField";
import { FormStack } from "../components/FormStack/FormStack";
import { isValidEmail } from "../util/isValidEmail";
import { CurrencyField } from "../components/fields/CurrencyField/CurrencyField";
import { SwitchField } from "../components/fields/SwitchField/SwitchField";
import { BigNumber } from "bignumber.js";

const contactTypeOptions = [
  { label: "Home", value: "Home" },
  { label: "Work", value: "Work" },
  { label: "Postal", value: "Postal" },
];

// Is the number an australian local mobile number (04 xx...) that can
// be transformed into an international format.
const isLocalAustralianMobileNumber = (mobileNumber: string) => {
  const trimmed = mobileNumber.replace(/[^\d\+]/g, "");
  return /0[45][\d]{8}/.test(trimmed);
};

// Transforms a local australian number to an international format.
const transformLocalAustralianMobileNumber = (mobileNumber: string) => {
  const trimmed = mobileNumber.replace(/[^\d\+]/g, "");
  return trimmed.replace(/^0/, "+61");
};

interface InvitationFormValues {
  contactType: "Home" | "Work" | "Postal";
  email: string;
  mobile: string;
  cpcInitialBalance: string;
  cpcAppDownloadLink: boolean;
}

interface InvitationFormProps {
  apiClient: ApiClient;
  member: CherryPayApi.Member;
  businessId: string;
  invitationType: "digital-membership" | "cherry-pay-card";
  minimumBalance?: number | null;
  maximumBalance?: number | null;
  cpcType?: "instantgift" | "reloadable";
  isSmsOptional?: boolean | null;
  submitLabel: string;
  onCancel: () => void;
  onSuccess?: (message: string) => void;
  onFailure?: (message?: string) => void;
}

const getDefaultContactType = (
  member: CherryPayApi.Member
): "Home" | "Work" | "Postal" => {
  if (
    member.DefaultContactDetails &&
    ["Home", "Work", "Postal"].includes(member.DefaultContactDetails)
  ) {
    return member.DefaultContactDetails;
  } else {
    return "Home";
  }
};

const memberContactDetails = (
  member: CherryPayApi.Member,
  type: "Home" | "Work" | "Postal"
) => {
  return member[`${type}ContactDetails`];
};

const InnerForm = ({
  isSubmitting,
  isValid,
  onCancel,
  member,
  values,
  errors,
  invitationType,
  isSmsOptional,
  submitLabel,
  setValues,
  setFieldValue,
}: InvitationFormProps & FormikProps<InvitationFormValues>) => {
  const currentContactDetails = useMemo(
    () => memberContactDetails(member, values.contactType),
    [values.contactType, member]
  );

  const hasUpdatedMobile =
    (currentContactDetails?.MobileNumber ?? "") !== values.mobile;
  const hasUpdatedEmail =
    (currentContactDetails?.EmailAddress ?? "") !== values.email;

  const onClickTransform = useCallback(() => {
    setFieldValue(
      "mobile",
      transformLocalAustralianMobileNumber(values.mobile),
      true
    );
  }, [setFieldValue, values.mobile]);

  const canTransformNumber = useMemo(
    () => isLocalAustralianMobileNumber(values.mobile),
    [values.mobile]
  );

  const transformedNumber = useMemo(
    () => transformLocalAustralianMobileNumber(values.mobile),
    [values.mobile]
  );

  // Select member contact details
  useEffect(() => {
    setValues(
      (values) => ({
        ...values,
        email: currentContactDetails?.EmailAddress ?? "",
        mobile: currentContactDetails?.MobileNumber ?? "",
      }),
      true
    );
  }, [
    currentContactDetails?.EmailAddress,
    currentContactDetails?.MobileNumber,
    setValues,
  ]);

  return (
    <FormStack>
      <Box mb="2">
        <MemberInfoBox member={member} />
      </Box>

      <SelectField
        name="contactType"
        label="Contact details"
        options={contactTypeOptions}
        allowEmpty={false}
      />

      <TextField
        name="email"
        label="Email address"
        placeholder="Email address"
        isValid={!!values.email && !errors.email}
      />

      <Box width="100%">
        <TextField
          name="mobile"
          label="Mobile number"
          placeholder="Mobile number"
          isValid={!!values.mobile && !errors.mobile}
        />

        <Collapse in={canTransformNumber} animateOpacity>
          <Button variant="link" onClick={onClickTransform} size="xs">
            Use international format: {transformedNumber}
          </Button>
        </Collapse>
      </Box>

      <Collapse
        in={isValid && (hasUpdatedEmail || hasUpdatedMobile)}
        animateOpacity
      >
        <Alert status="warning" size="sm" marginTop="2">
          <AlertIcon />
          Updates to member contact details will be saved.
        </Alert>
      </Collapse>

      {invitationType === "cherry-pay-card" && (
        <CurrencyField
          name="cpcInitialBalance"
          label="Initial balance"
          placeholder="0.00"
          aria-label="Initial balance"
        />
      )}

      {invitationType === "cherry-pay-card" && isSmsOptional !== false && (
        <>
          <SwitchField
            name="cpcAppDownloadLink"
            label="Send CherryPay app download link"
            aria-label="Send CherryPay app download link"
          />
          <Collapse in={values.cpcAppDownloadLink === true} animateOpacity>
            <Alert status="info" size="sm">
              <AlertIcon />
              The member will receive a link to download the CherryPay app.
            </Alert>
          </Collapse>
        </>
      )}

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          {submitLabel}
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const InvitationForm = withFormik<
  InvitationFormProps,
  InvitationFormValues
>({
  validateOnMount: false,
  validateOnBlur: false,

  mapPropsToValues: (props) => {
    const defaultContactType = getDefaultContactType(props.member);
    const contactDetails = memberContactDetails(
      props.member,
      defaultContactType
    );
    return {
      contactType: getDefaultContactType(props.member),
      email: contactDetails?.EmailAddress ?? "",
      mobile: contactDetails?.MobileNumber ?? "",
      cpcInitialBalance: "0.00",
      cpcAppDownloadLink: true,
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    const { member, businessId, apiClient, onSuccess, onFailure } = props;
    const memberId = member.id;
    const contactDetails = memberContactDetails(member, values.contactType);

    // If the members contact details have been updated by this form we need to fire off an update request
    // before issuing the digital membership invite.
    if (
      contactDetails?.EmailAddress !== values.email ||
      contactDetails?.MobileNumber !== values.mobile
    ) {
      const updateResult = await apiClient.setMemberProperties(
        businessId,
        memberId,
        {
          Key: `${values.contactType}ContactDetails`,
          Value: {
            ...contactDetails,
            MobileNumber: values.mobile,
            EmailAddress: values.email,
          },
        }
      );
      if (!updateResult.ok) {
        if (onFailure) {
          onFailure(updateResult.message);
        }
      }
    }

    let inviteResult: ApiResult<any>;
    let successMessage: string;

    if (props.invitationType === "digital-membership") {
      inviteResult = await apiClient.sendDigitalMembershipInvitation(
        businessId,
        memberId,
        `${values.contactType}ContactDetails`
      );
      successMessage = "Digital membership invitation sent.";
    } else if (props.invitationType === "cherry-pay-card" && !!props.cpcType) {
      inviteResult = await apiClient.sendCherryPayCardInvitation(
        businessId,
        memberId,
        `${values.contactType}ContactDetails`,
        new BigNumber(values.cpcInitialBalance).toNumber(),
        props.isSmsOptional === true ? values.cpcAppDownloadLink : null,
        props.cpcType
      );
      successMessage = "CherryPay Card invitation sent.";
    } else {
      throw new Error(`Invalid invitationType ${props.invitationType}`);
    }

    if (inviteResult.ok) {
      if (onSuccess) {
        onSuccess(successMessage);
      }
    } else {
      if (onFailure) {
        onFailure(inviteResult.message);
      }
    }
  },

  validate: (values, { maximumBalance, minimumBalance, invitationType }) => {
    let errors: FormikErrors<InvitationFormValues> = {};

    if (isEmptyStr(values.mobile) && isEmptyStr(values.email)) {
      errors.mobile = "Mobile number is required.";
      errors.email = "Email address is required.";
    }

    if (!isEmptyStr(values.mobile)) {
      if (isLocalAustralianMobileNumber(values.mobile)) {
        errors.mobile = "Mobile number must include country code";
      } else if (!/^\+[0-9]{8,13}$/.test(values.mobile)) {
        errors.mobile =
          "Invalid mobile number. Use full number with country code.";
      }
    }

    if (!isEmptyStr(values.email) && !isValidEmail(values.email)) {
      errors.email = "Invalid email address.";
    }

    if (invitationType === "cherry-pay-card") {
      if (new BigNumber(values.cpcInitialBalance).lt(0)) {
        errors.cpcInitialBalance = "Initial balance cannot be less than $0.00";
      }
    }

    if (
      invitationType === "cherry-pay-card" &&
      typeof minimumBalance === "number"
    ) {
      if (new BigNumber(values.cpcInitialBalance).lt(minimumBalance)) {
        errors.cpcInitialBalance = `Initial balance must be at least $${new BigNumber(
          minimumBalance
        ).toFormat(2)}`;
      }
    }

    if (
      invitationType === "cherry-pay-card" &&
      typeof maximumBalance === "number"
    ) {
      if (new BigNumber(values.cpcInitialBalance).gt(maximumBalance)) {
        errors.cpcInitialBalance = `Initial balance cannot exceed $${new BigNumber(
          maximumBalance
        ).toFormat(2)}`;
      }
    }

    return errors;
  },
})(InnerForm);
