import React, { useCallback, useMemo } from "react";
import { FormikErrors, FormikProps, withFormik, FieldInputProps } from "formik";
import { FormHelperText } from "@chakra-ui/form-control";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { ApiClient, ApiResult } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { arraysAreEqual } from "../util/arraysAreEqual";
import { SelectField } from "../components/fields/SelectField/SelectField";
import { FieldWrapper } from "../components/fields/FieldWrapper/FieldWrapper";
import { FormStack } from "../components/FormStack/FormStack";

interface EditUserFormValues {
  role: string;
  authorisedVenues: string[];
}

interface EditUserFormProps {
  user: CherryPayApi.UserAccount;
  apiClient: ApiClient;
  businessId: string;
  business: CherryPayApi.BusinessSummary;
  availableRoles: CherryPayApi.Role[];
  onCancel: () => void;
  onSuccess?: (user: CherryPayApi.UserAccount) => void;
  onFailure?: (message?: string) => void;
}

const getUserRole = (userAccount: CherryPayApi.UserAccount) => {
  const cherryPayRole = (userAccount.CustomClaims ?? {})["cherryPayRole"];
  if (typeof cherryPayRole === "string") {
    return cherryPayRole;
  }
  return "";
};

const getUserAuthorisedVenues = (
  userAccount: CherryPayApi.UserAccount
): string[] => {
  const cherryPayAuthorisedVenues =
    userAccount.CustomClaims["cherryPayAuthorisedVenues"];
  if (typeof cherryPayAuthorisedVenues === "string") {
    return cherryPayAuthorisedVenues.split(",") as string[];
  }
  return [];
};

const AuthorisedVenueCheckboxGroup = ({
  field,
  form,
  venues,
}: {
  field: FieldInputProps<number[]>;
  form: FormikProps<any>;
  venues: CherryPayApi.VenueSummary[];
}) => {
  const onChange = useCallback(
    (val: string[]) => form.setFieldValue(field.name, val),
    [form.setFieldValue]
  );

  return (
    <CheckboxGroup {...field} onChange={onChange}>
      <VStack w="100%" mt="2" mb="2">
        {venues.map(({ DisplayName, VenueNo }) => {
          return (
            <Checkbox key={VenueNo} value={VenueNo.toString()} w="100%">
              {DisplayName}
            </Checkbox>
          );
        })}
      </VStack>
    </CheckboxGroup>
  );
};

const InnerForm = ({
  isSubmitting,
  submitForm,
  isValid,
  onCancel,
  values,
  business,
  availableRoles,
  dirty,
}: EditUserFormProps & FormikProps<EditUserFormValues>) => {
  const roleOptions = useMemo(
    () =>
      availableRoles.map((role) => ({ label: role.Name, value: role.Name })),
    [availableRoles]
  );
  return (
    <FormStack>
      <SelectField
        label="Role"
        name="role"
        options={roleOptions}
        allowEmpty={true}
      />

      {/* <FieldWrapper name="authorisedVenues" label="Restrict Venue Access">
        {({ field, form }) => (
          <>
            <FormHelperText>
              If this users access should be restricted to specific venues,
              select them below.
            </FormHelperText>
            <AuthorisedVenueCheckboxGroup
              venues={business.VenueSummaries}
              form={form}
              field={field}
            />
            {values.authorisedVenues.length === 0 && (
              <Alert status="info" size="sm">
                <AlertIcon />
                <Text>
                  This user will have access to <strong>all</strong> venues
                  within the business.
                </Text>
              </Alert>
            )}
          </>
        )}
      </FieldWrapper> */}

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          colorScheme="cherryButton"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid}
          onClick={dirty ? submitForm : onCancel}
        >
          Save
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const EditUserForm = withFormik<EditUserFormProps, EditUserFormValues>({
  mapPropsToValues: (props) => {
    return {
      role: getUserRole(props.user),
      authorisedVenues: getUserAuthorisedVenues(props.user),
    };
  },

  handleSubmit: async (values, { props }) => {
    let result: ApiResult<CherryPayApi.UserAccount> | null = null;

    // If the user role has been edited
    if (values.role && values.role !== getUserRole(props.user)) {
      result = await props.apiClient.updateUserRole(
        props.businessId,
        props.user.Uid,
        values.role
      );

      if (!result.ok) {
        if (props.onFailure) {
          props.onFailure(result.message);
        }
        return;
      }
    }

    if (
      !arraysAreEqual(
        values.authorisedVenues,
        getUserAuthorisedVenues(props.user)
      )
    ) {
      result = await props.apiClient.updateUserAuthorisedVenues(
        props.businessId,
        props.user.Uid,
        values.authorisedVenues
      );

      if (!result.ok) {
        if (props.onFailure) {
          props.onFailure(result.message);
        }
        return;
      }
    }

    if (props.onSuccess && result?.data) {
      props.onSuccess(result?.data);
    }
  },

  validateOnBlur: false,

  validate: (values, { user }) => {
    let errors: FormikErrors<EditUserFormValues> = {};
    if (values.role === "") {
      errors.role = "A role must be selected.";
    }

    if (getUserRole(user) && !values.role) {
      errors.role = "Cannot remove role from user.";
    }

    return errors;
  },
})(InnerForm);
