/**
 * Compares contents of two arrays ignoring the order.
 */
export const arraysAreEqual = <T>(a: T[], b: T[]) => {
  if (a.length !== b.length) {
    return false;
  }

  const bSet = new Set(b);

  for (let i of a) {
    if (!bSet.has(i)) {
      return false;
    }
  }

  return true;
};
