import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { User } from "firebase/auth";
import { useFirebase } from "./FirebaseContext";
import { ApiClient, ApiResult } from "../api/apiClient";
import { CherryPayApi } from "../api/models";

export interface JWTUser {
  cherryPayRole?: string;
  hubAdminApiRole?: string;
  businessId?: string;
}

const isValidJWTUser = (decodedJwt: {
  [key: string]: any;
}): decodedJwt is JWTUser => {
  const hasCherryPayRole =
    "cherryPayRole" in decodedJwt &&
    typeof decodedJwt["cherryPayRole"] === "string";

  const hasBusinessId =
    "businessId" in decodedJwt && typeof decodedJwt["businessId"] === "string";

  const hasHubAdminRole =
    "hubAdminApiRole" in decodedJwt &&
    typeof decodedJwt["hubAdminApiRole"] === "string";

  return hasHubAdminRole || (hasCherryPayRole && hasBusinessId);
};

const decodeJwt = (accessToken: string) => {
  try {
    return JSON.parse(window.atob(accessToken.split(".")[1]));
  } catch (e) {
    return {};
  }
};

export type Permission =
  | "Reporting.Financial"
  | "Reporting.Membership"
  | "Cardholder.CreditMoney"
  | "Cardholder.PointsToMoney"
  | "Loyalty.CreditPoints"
  | "Member.InviteToCard"
  | "Member.InviteToApp"
  | "Member.ReadDetails"
  | "Member.Search"
  | "User.Management";

export interface UserContext {
  /** The current logged in user */
  user: User | null;

  /**
   * The business id which a users access should be scoped to.
   */
  userBusinessId: string | null;

  /** Api client to make authenticated api calls */
  apiClient: ApiClient | null;

  /** Log out of the current session */
  logout: () => Promise<void>;
  /** Is the user context still initialising? */
  isLoading: boolean;
  isSuperAdmin: boolean;
  permissions: Set<string>;
}

export const UserContext = createContext<UserContext | null>(null);

interface UserContextProviderProps {
  children: React.ReactNode;
}

const useDecodedJwt = (user: User | null) => {
  return useMemo<JWTUser | null>(() => {
    if (!user) {
      return null;
    }
    const decoded = decodeJwt(user.stsTokenManager.accessToken);

    if (isValidJWTUser(decoded)) {
      return decoded as JWTUser;
    } else {
      return null;
    }
  }, [user]);
};

/** Fetches the permissions associated with the current users role and business */
const usePermissions = (
  user: User | null,
  userJwt: JWTUser | null
): { isLoading: boolean; data: Set<string> | null } => {
  const [permissionsState, setPermissionsState] = useState<{
    isLoading: boolean;
    data: Set<string> | null;
  }>({ isLoading: true, data: null });

  useEffect(() => {
    if (!user || !userJwt) {
      return;
    }
    if (userJwt.businessId && userJwt.cherryPayRole) {
      const apiClient = new ApiClient(user);
      setPermissionsState({
        isLoading: true,
        data: null,
      });
      apiClient
        .getBusinessRoles(userJwt.businessId)
        .then((result) => {
          let permissions: Set<string> | null = null;
          if (result.ok) {
            const userRole = result.data.find(
              (role) => role.Name === userJwt.cherryPayRole
            );
            if (userRole) {
              permissions = new Set(userRole.GrantedPermissions);
            }
          }
          setPermissionsState({
            isLoading: false,
            data: permissions,
          });
        })
        .catch((error) => {
          console.error(error);
          setPermissionsState({
            isLoading: false,
            data: null,
          });
        });
    } else {
      setPermissionsState({
        isLoading: false,
        data: null,
      });
    }
  }, [user, userJwt, setPermissionsState]);

  return permissionsState;
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { auth } = useFirebase();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoading(false);
    });
  }, [auth, setUser, setIsLoading]);

  const apiClient = useMemo(
    () => (user ? new ApiClient(user) : new ApiClient()),
    [user]
  );

  // Decode the JWT so that we can access the user claims and determine their role
  const userJwt = useDecodedJwt(user);

  // Fetch the permissions for the current users role.
  const permissionsState = usePermissions(user, userJwt);

  const isSuperAdmin = userJwt?.hubAdminApiRole === "SuperAdmin";

  const permissionsIsLoading = !!user && permissionsState.isLoading;

  const ctx = useMemo<UserContext>(
    () => ({
      user,
      apiClient,
      isLoading: isLoading || permissionsIsLoading,
      logout: () => auth.signOut(),
      userBusinessId: userJwt?.businessId ?? null,
      isSuperAdmin: userJwt?.hubAdminApiRole === "SuperAdmin",
      permissions: permissionsState.data ?? new Set<string>([]),
    }),
    [
      isLoading,
      permissionsIsLoading,
      auth,
      apiClient,
      user,
      userJwt,
      permissionsState.data,
      setUser,
    ]
  );

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContext => {
  const ctx = useContext(UserContext);

  if (!ctx) {
    throw new Error("Missing user context");
  }

  return ctx;
};
