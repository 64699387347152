import { Text } from "@chakra-ui/layout";
import { Switch, SwitchProps } from "@chakra-ui/switch";
import React from "react";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface SwitchFieldProps extends BaseFieldProps {}

export const SwitchField = ({
  name,
  label,
  id,
  helperText,
  ...switchProps
}: SwitchFieldProps & SwitchProps) => {
  return (
    <FieldWrapper name={name} id={id} label={label} helperText={helperText}>
      {({ field }) => (
        <Switch {...field} {...switchProps} isChecked={field.value} />
      )}
    </FieldWrapper>
  );
};
