import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Box, HStack } from "@chakra-ui/layout";

import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { Checkbox } from "@chakra-ui/checkbox";
import { useCheckboxState } from "../../hooks/useCheckboxState";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useApiClient } from "../../hooks/useApiClient";
import { useModalState } from "../../hooks/useModalState";
import { CherryPayApi } from "../../api/models";
import { VenueModal } from "../../components/VenueModal/VenueModal";
import { Button } from "@chakra-ui/react";
import { PlusIcon } from "../../styles/icons";
import { useToast } from "../../hooks/useToast";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { TermsConditionsTable } from "../../components/TermsConditionsTable/TermsConditionsTable";
import { useFirebase } from "../../context/FirebaseContext";
import {
  getFirestore,
  Firestore,
  addDoc,
  updateDoc,
  collection,
  query as fireQuery,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

export const TermsConditionsList = () => {
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const { db } = useFirebase();
  const navigate = useNavigate();
  const { checked: showDeletedVenues, onChange: onChangeDeletedVenuesCheck } =
    useCheckboxState(false);

  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const modalState = useModalState<CherryPayApi.VenueDocument | null>();

  const {
    debouncedQuery,
    isDebouncing,
    onChange: onChangeSearch,
    query,
  } = useSearchInputState();

  const [items, setItems] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // const {
  //   data: venues,
  //   result,
  //   refresh,
  // } = useApiRequest(
  //   (apiClient) =>
  //     apiClient.getBusinessVenues(business.BusinessId, {
  //       q: debouncedQuery,
  //       includeDeleted: showDeletedVenues ? "true" : "false",
  //     }),
  //   [business.BusinessId, debouncedQuery, showDeletedVenues]
  // );

  const getData = useCallback(async () => {
    const result = await apiClient.getTermsConditionsList(
      db,
      business.BusinessId
    );
    console.log(result.docs);
    setItems(result.docs.map((s) => ({ ...s.data(), id: s.id })));
    setIsLoading(false);
  }, [db]);

  useEffect(() => {
    getData();
  }, []);

  const onClickNewVenue = useCallback(
    () => navigate("./new"),
    [modalState.onOpen]
  );

  const onClickEditVenue = useCallback(
    (venue: CherryPayApi.VenueDocument) => {
      // modalState.onOpen(venue)
      navigate("./" + venue.id);
    },
    [modalState.onOpen]
  );

  const onClickDeleteVenue = useCallback(
    (venue: CherryPayApi.VenueDocument) =>
      showConfirmationDialog(
        "Delete?",
        "Are you sure you wish to delete this record?",
        "Delete"
      ).then(async ({ ok }) => {
        if (ok) {
          await apiClient.deleteTermsConditions(
            db,
            business.BusinessId,
            venue.id
          );
          showSuccessToast("Deleted successfully.");
          getData();
        }
      }),
    [showSuccessToast, showErrorToast, showConfirmationDialog, apiClient]
  );

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          {/* <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/configuration`}
            >
              Configuration
            </BreadcrumbLink>
          </BreadcrumbItem> */}
        </BusinessBreadcrumbs>
        <PageHeading.Title>Terms &amp; Conditions</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <HStack w="100%">
          {/* <Box flex="4" maxWidth="400px">
            <SearchInput
              isLoading={isDebouncing || isLoading}
              value={query}
              onChange={onChangeSearch}
              placeholder="Search venues..."
            />
          </Box>
          <Box flex="1">
            <Checkbox
              checked={showDeletedVenues}
              onChange={onChangeDeletedVenuesCheck}
              title="Include deleted venues in the list."
            >
              Deleted Venues
            </Checkbox>
          </Box> */}
          <Box marginLeft="auto">
            <Button
              leftIcon={<PlusIcon />}
              onClick={onClickNewVenue}
              colorScheme="cherryButton"
            >
              New Item
            </Button>
          </Box>
        </HStack>

        <TermsConditionsTable
          venues={items}
          business={business}
          isLoading={!items && isLoading}
          disableActions={isLoading}
          onClickEditVenue={onClickEditVenue}
          onClickDeleteVenue={onClickDeleteVenue}
        />

        {isLoading && <LoadingIndicator />}

        {confirmationElement}
      </PageContent>
    </>
  );
};
