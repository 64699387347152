import React, { ReactNode, useCallback, useState } from "react";

interface ErrorBoundaryProps {
  onCatch: (error: any, errorInfo: any) => void;
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: any, errorInfo: any) {
    this.props.onCatch(error, errorInfo);
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

interface ErrorHandlerProps {
  /**
   * Render method to be called when the component has caught an error
   */
  renderErrorState: (onReset: () => void) => ReactNode;

  children: ReactNode;
}

export const ErrorHandler = ({
  children,
  renderErrorState,
}: ErrorHandlerProps) => {
  const [didCatch, setDidCatch] = useState(false);

  const onCatch = useCallback(
    (error, errorInfo) => {
      // TODO - log to sentry?
      console.error(error);

      // Display error state
      setDidCatch(true);
    },
    [setDidCatch]
  );

  const onReset = useCallback(() => setDidCatch(false), [setDidCatch]);

  return (
    <ErrorBoundary onCatch={onCatch}>
      {didCatch ? renderErrorState(onReset) : children}
    </ErrorBoundary>
  );
};
