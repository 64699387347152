import { useUserContext } from "../context/UserContext";

export const useApiClient = () => {
  const { apiClient } = useUserContext();

  if (!apiClient) {
    throw new Error("Cannot use api client without a user context.");
  }

  return apiClient;
};
