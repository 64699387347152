import React, { useCallback, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { PointsForm } from "../../forms/PointsForm";
import { useApiClient } from "../../hooks/useApiClient";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { useToast } from "../../hooks/useToast";
import { useUserContext } from "../../context/UserContext";
import { ApiLoadingIndicator } from "../ApiLoadingIndicator/ApiLoadingIndicator";

interface PointsRequestModalProps {
  pointsRequestType?: "add-points" | "transfer-points-to-cpc";
  memberId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const PointsRequestModal = ({
  memberId,
  isOpen,
  onClose,
  pointsRequestType,
}: PointsRequestModalProps) => {
  const initialRef = useRef<HTMLInputElement>(null);
  const apiClient = useApiClient();
  const { user } = useUserContext();
  const business = useBusinessContext();
  const { showErrorToast, showSuccessToast } = useToast();

  const apiRequest = useApiRequest(
    (apiClient) => {
      if (!isOpen || !memberId) {
        return null;
      }

      return apiClient.getPointsRequestModalObjects(
        business.BusinessId,
        memberId
      );
    },
    [memberId, business]
  );

  const onSuccess = useCallback(
    (message: string) => {
      showSuccessToast(message);
      onClose();
    },
    [onClose, showSuccessToast]
  );

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {pointsRequestType === "add-points"
            ? "Add Points to Member"
            : "Transfer Points to DMC"}
        </ModalHeader>
        <ModalBody pb="6">
          <ApiLoadingIndicator
            renderLoading={() => <LoadingIndicator color="#333" />}
            renderError={() => (
              <ErrorMessage>
                {"An error was encountered while loading the form."}
              </ErrorMessage>
            )}
            apiStates={[apiRequest]}
          >
            {([{ Member, PointsTypes }]) => (
              <PointsForm
                username={user?.email ?? ""}
                apiClient={apiClient}
                businessId={business.BusinessId}
                member={Member}
                formType={pointsRequestType!}
                pointsTypes={PointsTypes}
                onCancel={onClose}
                onSuccess={onSuccess}
                onFailure={showErrorToast}
              />
            )}
          </ApiLoadingIndicator>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
